<template>
    <div v-if="!nosTabSelected" class="matrix-sum">
        <div :class="{'matrix-sum__table': true}">
            <div class="matrix-sum__table-header">
                <span class="matrix-sum__first-cell">{{ $t('Matrix.delivery_date') }}</span>
                <span>{{ $t('Matrix.deadline') }}</span>
                <span class="quantity">{{ $t('Matrix.total_pieces') }}</span>
                <span class="trading-margin">{{ $t('Matrix.trading_margin') }}</span>
                <span class="prices">{{ $t('General.net_ek') }}</span>
                <span class="prices">{{ $t('Matrix.upe') }}</span>
            </div>
            <div class="matrix-sum__row matrix-sum__row__supplier">
                <span class="header spacing-right--xs">{{ $t('General.supplier') }}</span>
                <span class="matrix-sum__cell supplier-name">{{ data[0]?.supplierName }}</span>
            </div>
            <div v-for="(cell, key) in data" :key="key" class="matrix-sum__row">
                <span class="matrix-sum__cell matrix-sum__first-cell">{{ useDateFormat(cell.deliveryDate) }}</span>
                <span class="matrix-sum__cell">
                    <AtomMatrixDateLabel
                        :text="useDateFormat(cell.deadline, 'DD.MM.YYYY')"
                        :color="checkDeliveryDateDeadline(dateToString(cell.deadline))"
                        :deadline-date="useDateFormat(new Date(cell.deadline ?? ''), 'DD.MM.YYYY')"
                    />
                </span>
                <span class="matrix-sum__cell quantity">{{ cell.productsCount }}</span>
                <span class="matrix-sum__cell trading-margin">{{ useCalculateTradingMargin(cell.sumPurchasePrice, cell.sumSellPrice) }}</span>
                <span class="matrix-sum__cell prices">{{ useMoneyFormat(cell.sumPurchasePrice) }}</span>
                <span class="matrix-sum__cell prices">{{ useMoneyFormat(cell.sumSellPrice) }}</span>
            </div>
            <div class="matrix-sum__row matrix-sum__row__totals">
                <span class="matrix-sum__cell matrix-sum__first-cell">{{ $t('Matrix.total_sum') }}</span>
                <span class="matrix-sum__cell deadline"/>
                <span class="matrix-sum__cell quantity">{{ getTotals().productsCount }}</span>
                <span class="matrix-sum__cell trading-margin">{{ getTotals().tradingMargin }}</span>
                <span class="matrix-sum__cell prices">{{ useMoneyFormat(getTotals().sumPurchasePrice) }}</span>
                <span class="matrix-sum__cell prices">{{ useMoneyFormat(getTotals().sumSellPrice) }}</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { MatrixSumProps } from '~/composables/types/api/searchDiscover/getMatrix';
import useMoneyFormat from '~/composables/utils/useMoneyFormat';
import useCalculateTradingMargin from '~/composables/utils/useCalculateTradingMargin';
import useDateFormat from '~/composables/utils/useDateFormat';
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';

type Totals = {
    sumPurchasePrice: number,
    sumSellPrice: number,
    productsCount: number,
    tradingMargin: string,
}

const props = defineProps<{
    data: MatrixSumProps[],
    nosTabSelected: boolean,
}>();

const {
    dateToString,
    checkDeliveryDateDeadline,
} = useMatrixDateHelper();

const getTotals = (): Totals => {
    const newTotals: Totals = {
        sumSellPrice: 0,
        sumPurchasePrice: 0,
        productsCount: 0,
        tradingMargin: '',
    };

    props.data.map((date) => {
        newTotals.sumSellPrice += date.sumSellPrice ?? 0;
        newTotals.sumPurchasePrice += date.sumPurchasePrice ?? 0;
        newTotals.productsCount += date.productsCount ?? 0;
    });

    newTotals.tradingMargin = useCalculateTradingMargin(newTotals.sumPurchasePrice, newTotals.sumSellPrice);

    return newTotals;
};
</script>

<style lang="scss" scoped>
.matrix-sum {
    justify-content: flex-end;
    display: flex;

    &__table {
        width: rem(700);
    }

    &__table-header {
        justify-content: flex-start;
        font-size: fs(big);
        font-weight: fw(medium);
        margin-top: sp(s);
        gap: rem(24);
        text-align: left;
        display: flex;

        @include helper-color(text-title);

        > span {
            width: 100%;
            text-transform: uppercase;
            text-align: left;

            &:first-child {
                flex-basis: 25%;
                text-align: left;
                padding-left: rem(6);
            }

            &:not(:first-child) {
                flex-basis: calc(75% / 4);
            }

            &.quantity {
                text-align: center;
            }

            &.trading-margin {
                text-align: center;
            }

            &.prices {
                text-align: right;
            }

            .condition {
                font-size: fs(smaller);
            }
        }
    }

    &__row {
        display: flex;
        justify-content: flex-start;
        margin-top: sp(xs);
        gap: rem(24);
        text-align: left;

        &__totals {
            padding: rem(8) 0;

            @include helper-border-t();

            span:first-child {
                font-weight: fw(medium);
                white-space: nowrap;
                text-transform: uppercase;
            }
        }

        > span {
            &:first-child {
                text-align: left;
                padding-left: rem(6);
                flex-basis: 25%;
            }

            &:last-child,
            &:nth-child(4) {
                text-align: right;
            }

            &:not(:first-child) {
                flex-basis: calc(75% / 4);
            }

            &:not(.supplier-name):nth-child(2) {
                display: flex;
                justify-content: flex-start;
            }

            &.quantity {
                text-align: center;
            }

            &.trading-margin {
                text-align: center;
            }

            &.deadline {
                min-width: rem(81);
            }

            &.prices {
                text-align: right;
            }
        }

        &__supplier {
            display: inline-block;
            font-size: fs(default);
            font-weight: fw(medium);
            width: 100%;
            padding: rem(8) 0;
            text-align: left;

            @include helper-color(text-primary);
            @include helper-border-t();
            @include helper-border-b();
        }
    }

    &__first-cell {
        min-width: rem(130);
    }
}
</style>
