import getBranchClusters from '~/composables/api/searchDiscover/getBranchClusters';
import type { BranchClusters } from '~/composables/types/api/searchDiscover/getBranchClusters';
import {
    useBranchClusterStore
} from '~/composables/stores/useBranchClusterStore';

export default function useBranchClusters(abstractIdOrSku: string | null) {
    const { $t } = useNuxtApp();
    const branchClusterData: Ref<BranchClusters | null> = ref(null);
    const moduleBranchClusterData: Ref<BranchClusters | null> = ref(null);
    const toasts = useToasts();
    const {
        updateBranchClusterTemplate,
        updateModuleClusterTemplate,
        getClusterState,
        setClusterState,
        setClusterLoadingState,
        setModuleQuantitiesMatch,
    } = useBranchClusterStore();

    const loadBranchClusters = async(initialLoad: boolean, moduleName: string | null, allocationId: string | null): Promise<boolean> => {
        if (abstractIdOrSku) {
            setClusterLoadingState(true);
            const branchClusterResponse = await getBranchClusters(abstractIdOrSku, moduleName, allocationId);
            setClusterLoadingState(false);

            if (branchClusterResponse.state !== 'success') {
                if (branchClusterResponse.status && branchClusterResponse.status >= ResponseStatusTypes.SERVER_ERROR) {
                    toasts.add('LOAD_ERROR');
                }

                if (!initialLoad && branchClusterResponse.status === ResponseStatusTypes.NOT_FOUND) {
                    toasts.add({
                        type: 'warning',
                        headline: $t('Matrix.branch_cluster_toast_headline'),
                        text: $t('Matrix.branch_cluster_toast'),
                    });
                    branchClusterData.value = null;
                }

                return false;
            }

            branchClusterResponse.resetData = false;
            branchClusterData.value = branchClusterResponse;
            moduleBranchClusterData.value = null;

            if (!moduleName || !branchClusterData.value?.modules || branchClusterData.value?.modules?.length === 0) {
                setModuleQuantitiesMatch(false);
            }

            if (moduleName && branchClusterData.value?.modules && branchClusterData.value?.modules?.length > 0) {
                const matchingModules = branchClusterData.value?.modules?.filter(
                    (module) => module.branchClusterName === moduleName
                );

                if (branchClusterData.value.modules && matchingModules) {
                    branchClusterData.value.modules = matchingModules?.length > 0
                        ? matchingModules
                        : [branchClusterData.value?.modules?.[0]];
                }
                moduleBranchClusterData.value = JSON.parse(JSON.stringify(branchClusterData.value));

                if (moduleBranchClusterData.value?.modules?.length) {
                    branchClusterData.value.branchClusters = branchClusterData.value.modules;
                }

                const clusterTemplate = moduleBranchClusterData?.value?.modules?.find(
                    (clusterTemplate: BranchClusterState['branchClusterTemplate']) => (
                        clusterTemplate?.branchClusterName === moduleName || clusterTemplate?.branchClusterName === allocationId
                    )
                );

                clusterTemplate?.clusters.forEach((cluster, index) => {
                    if (clusterTemplate?.clusters?.length && index < clusterTemplate?.clusters?.length - 1) {
                        cluster.branches = [];
                        cluster.branchNames = [];
                        cluster.partnerIds = {};
                    }
                });
                branchClusterData.value.modules = [];
            }

            return true;
        }

        return false;
    };

    const updateBranchClusterById = (clusterId: number | null, resetData: boolean, initialClusterSetup: boolean) => {
        let clusterTemplate = branchClusterData.value?.branchClusters?.find(
            branchCluster => branchCluster.branchClusterId === clusterId
        ) ?? null;

        const moduleClusterTemplate = moduleBranchClusterData.value?.modules?.find(
            branchCluster => branchCluster.branchClusterId === clusterId
        ) ?? null;

        if (!clusterTemplate && !resetData){
            clusterTemplate = branchClusterData.value?.modules?.find(
                branchCluster => branchCluster.branchClusterId === clusterId
            ) ?? null;
        }

        if (clusterTemplate) {
            clusterTemplate.resetData = resetData;
            clusterTemplate.initialClusterSetup = initialClusterSetup;
        }

        updateBranchClusterTemplate(clusterTemplate);
        updateModuleClusterTemplate(moduleClusterTemplate);
    };

    return {
        branchClusterData,
        moduleBranchClusterData,
        updateBranchClusterById,
        loadBranchClusters,
        getClusterState,
        setClusterState,
    };
}
