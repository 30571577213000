<template>
    <AtomModal
        :open="opened"
        class="date-modal"
        :buttons="modalButtons"
        :title="noFreeDateAvailable ? $t('Matrix.no_date_title') : undefined"
        :text="noFreeDateAvailable ? $t('Matrix.no_date_text') : undefined"
        :show-x-button="false"
        @button-clicked="buttonClicked"
        @update:open="buttonClicked(0)"
    >
        <template v-if="!noFreeDateAvailable">
            <div class="modal-box__title">
                {{ nosTabSelected ? $t('Matrix.delivery_date_nos_modal_title') : $t('Matrix.delivery_date_modal_title') }}
            </div>
            <AtomTabs v-if="isEdit && !nosTabSelected" v-model="editTabIndex" :items="editTabs" :line-style="true" />
            <div v-if="editTabIndex === 0 && !nosTabSelected" class="modal-box__text">
                {{ $t('Matrix.delivery_date_modal_text', [formatDateString(fromDate), formatDateString(toDate)]) }}
            </div>
            <div v-if="editTabIndex === 0 && nosTabSelected" class="modal-box__text">
                {{ $t('Matrix.delivery_nos_date_modal_text') }}
            </div>
            <div v-if="editTabIndex === 1" class="modal-box__text">
                {{ $t('Matrix.delivery_date_modal_delete_text') }}
            </div>

            <template v-if="dateType === dateTypeWeek && !nosTabSelected">
                <AtomSelect
                    v-model="selectedWeekDate"
                    :class="{'date-modal__disabled-selection': editTabIndex === 1}"
                    :options="getWeekOptions"
                />
            </template>

            <div v-else class="date-modal__date-wrapper">
                <div v-if="editTabIndex === 0" class="date-modal__nos-date">
                    <div v-if="nosTabSelected" class="date-modal__nos-date">
                        <div class="date-modal__date-label">
                            {{ $t('Matrix.start_delivery_date') }}:
                        </div>
                        <DatePicker
                            v-model:value="date"
                            :placeholder="$t('Matrix.select_date')"
                            value-type="YYYY-MM-DD"
                            :type="datePickerType"
                            :format="datePickerFormat"
                            :disabled-date="isInvalidNosDate"
                            :clearable="false"
                            :editable="false"
                            :append-to-body="false"
                        />
                    </div>
                    <div v-if="nosTabSelected" class="date-modal__date-separator"/>
                    <div v-if="nosTabSelected" class="date-modal__date-label">{{ $t('Matrix.end_delivery_date') }}:</div>
                    <DatePicker
                        v-if="nosTabSelected"
                        v-model:value="nosValidTo"
                        :placeholder="$t('Matrix.select_date')"
                        value-type="YYYY-MM-DD"
                        :type="datePickerType"
                        :format="datePickerFormat"
                        :disabled-date="isInvalidNosDate"
                        :clearable="true"
                        :editable="false"
                        :append-to-body="false"
                    />
                    <DatePicker
                        v-if="!nosTabSelected"
                        v-model:value="date"
                        :placeholder="$t('Matrix.select_date')"
                        value-type="YYYY-MM-DD"
                        :type="datePickerType"
                        :format="datePickerFormat"
                        :disabled-date="(calendarDate: Date) => isInvalidDate(calendarDate)"
                        :clearable="false"
                        :editable="false"
                        :append-to-body="false"
                    />
                </div>

                <div v-if="!nosTabSelected" class="modal-box__deadline">
                    <template v-if="editTabIndex === 0">
                        <span class="date-modal__date-text modal-box__deadline-text">{{ $t('Matrix.deadline') }}: </span>
                        <AtomMatrixDateLabel :text="formatDateString(minDeadlineDate(date ? date.toString() : ''))" />
                    </template>
                    <template v-else-if="editTabIndex === 1">
                        <span class="date-modal__date-text modal-box__deadline-text">{{ $t('Matrix.delivery_date_modal_title') }}: </span>
                        <AtomMatrixDateLabel :text="formatDateString(deletingDate)" />
                    </template>
                </div>
            </div>

            <template v-if="editTabIndex === 0 && !nosTabSelected">
                <div
                    v-if="isEdit && productsNotAvailableAtDate(date ? date.toString() : '', initialDate ?? '')"
                    class="modal-box__edit-warning"
                >
                    {{ $t('Matrix.edit_products_not_available') }}
                </div>
                <div class="modal-box__colors modal-box__colors-title">{{ $t('Matrix.available_colors') }}</div>
                <div class="modal-box__colors-wrapper">
                    <div v-for="(color, key) in availableColors(date ? date.toString() : '')" :key="key" class="modal-box__colors">
                        {{ color.label }}
                    </div>
                </div>
                <div class="date-modal__save-date-wrapper">
                    <div class="date-modal__save-date-line"/>
                    <div class="date-modal__save-date-header-wrapper">
                        <span class="modal-box__text">{{ $t('Matrix.remember_delivery_date') }}</span>
                        <AtomIcon
                            name="info"
                            class="date-modal__save-date-icon"
                            @mouseenter="tooltipElement = $event.target as HTMLElement"
                            @mouseleave="tooltipElement = null"
                        />
                    </div>
                    <div class="date-modal__save-date-header-wrapper date-modal__save-date-check-box">
                        <AtomCheckBox
                            :checked="getDeliveryDateChecked(catalogId, supplier, supplierIsIntersport, date?.toString() ?? '')"
                            @update:model-value="updateDeliveryDateChecked($event, catalogId, supplier, date?.toString() ?? '')"
                        />
                        <span class="date-modal__date-text date-modal__save-date-text">
                            {{ $t('Matrix.delivery_date_save_text', [catalogId, supplier]) }}
                        </span>
                    </div>
                </div>
            </template>
        </template>
        <AtomTooltip :element="tooltipElement" :offset-y="15" :offset-x="-23" tooltip-type="default">
            {{ $t('Matrix.remember_delivery_date_tooltip_text') }}
        </AtomTooltip>
    </AtomModal>
</template>

<script lang="ts" setup>
import { isHoliday } from 'feiertagejs';
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';
import type { ModalButton } from '~/composables/types/ui';
import type {
    CachedDeliveryDateData,
    MatrixDeliveryDate
} from '~/composables/types/api/searchDiscover/getMatrix';

const saturday = 6;
const sunday = 0;
const dateTypeWeek = 2;
const dateTypeMonth = 3;
const defaultDaysCalculationValue = 8;

type AvailableColors = {
    label: string
}

const props = defineProps<{
    dateType: number,
    dateRanges: {
        fromDate: string,
        toDate: string,
    }[],
    reservedDates: string[],
    initialDate?: string,
    minDeadlineDate: (date: string) => string,
    availableColors: (date: string) => AvailableColors[],
    isEdit: boolean,
    productsNotAvailableAtDate: (date: string, originalDate: string) => boolean,
    nosTabSelected: boolean,
    nosValidToDate: string,
    catalogId: string,
    supplier: string,
    supplierIsIntersport: boolean,
    deliveryDates: MatrixDeliveryDate[],
}>();


const emit = defineEmits<{
    (e: 'dateSelectionSaved', date: string, validTo?: string): void,
    (e: 'dateSelectionClosed'): void,
    (e: 'dateSelectionDelete', date: string): void,
}>();

const {
    dateToString,
    stringToDate,
    formatDateString,
    dateStringToWeek,
    getDeliveryDateChecked,
    updateDeliveryDateChecked,
    updateDeliveryDateInCache,
    isInRange,
} = useMatrixDateHelper();

const toasts = useToasts();

const tooltipElement = ref<HTMLElement | null>(null);
const deletingDate = ref('');

const isHolidayOrSunday = (calendarDate: Date) => {
    const day = calendarDate.getDay();

    return day === saturday || day === sunday || isHoliday(calendarDate, 'ALL');
};

const getNosFromDate = (dateFrom: string) => {
    const dateLength = 10;
    const inputDate = new Date(dateFrom);
    inputDate.setDate(inputDate.getDate() + 1);

    return inputDate.toISOString().slice(0, dateLength);
};

const { $t } = useNuxtApp();
const opened = ref(false);
const date = ref(props.initialDate);
const nosValidTo = ref(props.nosValidToDate);
const editTabIndex = ref(0);
const selectedWeekDate = ref('');
let noFreeDateAvailable = false;
const weeks = ref<{
    week: number,
    year: number,
    date: string,
}[]>([]);
const months = ref<{
    month: number,
    year: number,
    date: string,
}[]>([]);

const getWeekOptions = computed(() => {
    if (editTabIndex.value === 1) {
        return [{
            name: `${dateStringToWeek(deletingDate.value)} | ${formatDateString(deletingDate.value, 'DD.MM.YY')}`,
            value: deletingDate.value
        }];
    }

    return weeks.value.map(week => ({
        name: `${dateStringToWeek(week.date)} | ${formatDateString(week.date, 'DD.MM.YY')}`,
        value: week.date,
    }));
});

// only used for day and month
const isInvalidDate = (calendarDate: Date, openEndSelection: boolean = false) => {
    const dateString = dateToString(calendarDate);
    const now = dateToString();

    if (props.dateType !== dateTypeMonth) {
        if (dateString < now ||
            isHolidayOrSunday(calendarDate) ||
            props.reservedDates.includes(dateString)) {
            return true;
        }

        if (props.initialDate && dateString === props.initialDate) {
            return false;
        }

        return !openEndSelection ?
            !props.dateRanges.some(range => isInRange(range, dateString)) :
            !props.dateRanges.some(range => dateString >= range.fromDate);
    }

    return !months.value.some(x => x.month === calendarDate.getMonth() && x.year === calendarDate.getFullYear());
};

const isInvalidNosDate = (calendarDate: Date) => {
    if (!props.nosTabSelected) {
        return false;
    }

    const dateString = dateToString(calendarDate);
    const nosFromDate = getNosFromDate(dateToString());

    return (
        isInvalidDate(calendarDate, true) ||
        dateString < nosFromDate ||
        (props.nosValidToDate !== '' && (nosFromDate > props.nosValidToDate || (props.initialDate && props.initialDate > props.nosValidToDate)))
    );
};

let fromDate: string;
let toDate: string;

if (props.dateRanges.length === 0) {
    editTabIndex.value = 1;
} else {
    fromDate = props.dateRanges[0].fromDate;
    toDate = props.dateRanges[0].toDate;

    for (const range of props.dateRanges) {
        if (range.fromDate < fromDate) {
            fromDate = range.fromDate;
        }
        if (range.toDate > toDate) {
            toDate = range.toDate;
        }
    }
}
if (date.value === '') {
    !props.nosTabSelected ? date.value = fromDate! : date.value = props.nosValidToDate;
}

let datePickerType = 'date';
let datePickerFormat = 'DD.MM.YYYY';

if (props.dateType === dateTypeWeek && !props.nosTabSelected) {
    datePickerType = 'week';
    datePickerFormat = 'KW ww.YYYY';

    const reservedWeeks = props.reservedDates.map(reservedDate => {
        const newDate = stringToDate(reservedDate);

        return {
            week: useDateWeek(newDate),
            year: newDate.getFullYear(),
        };
    });
    const initialWeek = props.initialDate ? useDateWeek(stringToDate(props.initialDate ?? '')) : 0;
    const initialYear = props.initialDate ? stringToDate(props.initialDate ?? '').getFullYear() : 0;
    let initialSelectedDateInDates = '';

    for (const range of props.dateRanges) {
        let weekDate = stringToDate(range.fromDate);
        while (weekDate.getTime() <= stringToDate(range.toDate).getTime()) {
            const week = useDateWeek(weekDate);
            const year = weekDate.getFullYear();
            if (!weeks.value.some(x => x.week === week && x.year === year)) {
                if (!reservedWeeks.some(x => x.week === week && x.year === year) || (week === initialWeek && year === initialYear)) {
                    weeks.value.push({
                        week: week,
                        year: year,
                        date: dateToString(weekDate),
                    });
                }
            }
            const initialWeekDate = props.initialDate ? dateStringToWeek(props.initialDate) : '';
            if (dateStringToWeek(dateToString(weekDate)) === initialWeekDate) {
                initialSelectedDateInDates = dateToString(weekDate);
            }
            weekDate = new Date(weekDate);
            weekDate.setDate(weekDate.getDate() + (weekDate.getDay() === 0 ? 1 : defaultDaysCalculationValue - weekDate.getDay()));
        }
    }
    noFreeDateAvailable = weeks.value.length === 0;

    if (!noFreeDateAvailable && initialSelectedDateInDates.length && props.initialDate) {
        selectedWeekDate.value = initialSelectedDateInDates;
    } else if (!noFreeDateAvailable) {
        selectedWeekDate.value = weeks.value?.[0]?.date ?? '';
    }
} else if (props.dateType === dateTypeMonth) {
    datePickerType = 'month';
    datePickerFormat = 'MM.YYYY';

    const reservedMonths = props.reservedDates.map(reservedDate => {
        const newDate = stringToDate(reservedDate);

        return {
            month: newDate.getMonth(),
            year: newDate.getFullYear()
        };
    });
    const initialMonth = props.initialDate ? stringToDate(props.initialDate).getMonth() : 0;
    const initialYear = props.initialDate ? stringToDate(props.initialDate).getFullYear() : 0;

    for (const range of props.dateRanges) {
        let monthDate = stringToDate(range.fromDate);
        while (monthDate <= stringToDate(range.toDate)) {
            const month = monthDate.getMonth();
            const year = monthDate.getFullYear();
            if (!months.value.some(x => x.month === month && x.year === year)) {
                if (!reservedMonths.some(x => x.month === month && x.year === year) || (month === initialMonth && year === initialYear)) {
                    months.value.push({
                        month: month,
                        year: year,
                        date: dateToString(monthDate),
                    });
                }
            }
            monthDate = new Date(monthDate);
            monthDate.setMonth(monthDate.getMonth() + 1);
            monthDate.setDate(1);
        }
        noFreeDateAvailable = months.value.length === 0;
        if (!props.initialDate && months.value.length > 0) {
            date.value = months.value?.[0]?.date ?? '';
        }
    }
} else if (!props.initialDate) {
    const maxDate = stringToDate(toDate!);
    const newDate = stringToDate(date.value ?? '');

    while (isInvalidDate(newDate)) {
        newDate.setDate(newDate.getDate() + 1);
        if (newDate > maxDate) {
            noFreeDateAvailable = true;
            break;
        }
    }
    date.value = !props.nosTabSelected ? dateToString(newDate) : props.nosValidToDate;
}

const editTabs = [
    {
        text: $t('General.edit'),
        value: 0,
        disabled: props.dateRanges.length === 0,
    },
    {
        text: $t('General.delete'),
        value: 1,
    },
];

const modalButtons: ModalButton[] = noFreeDateAvailable ?
    [{
        text: $t('General.ok'),
    }] :
    [{
        text: $t('General.cancel'),
        type: 'secondary',
    },
    {
        text: $t('General.save'),
        type: 'primary',
    }];

const checkNosDateSelection = (start: string, end: string) => {
    if (props.nosTabSelected && start >= end && end !== '') {
        toasts.add({
            type: 'warning',
            headline: $t('Matrix.nos_date_error_title'),
            text: $t('Matrix.nos_date_error_text'),
        });

        return false;
    }

    return true;
};

const updateLocalStorageDeliveryDate = () => {
    const flag = getDeliveryDateChecked(props.catalogId, props.supplier, props.supplierIsIntersport, date.value?.toString() ?? '');
    if (flag) {
        const supplierDeliveryDateData: CachedDeliveryDateData = {
            catalogId: props.catalogId ?? '',
            supplier: props.supplier ?? '',
            deliveryDateType: props.dateType === dateTypeWeek ? 'week' : 'date',
            deliveryDates: props.deliveryDates.map(obj => ({
                date: obj.value,
                flag: obj.flag ?? true
            }))
        };
        updateDeliveryDateInCache(supplierDeliveryDateData, props.catalogId, props.supplier);
    }
};

const handleDateTypeMonth = () => {
    const monthDate = stringToDate(date.value ?? '');
    emit('dateSelectionSaved',
        months.value.find(
            month => month.month === monthDate.getMonth() && month.year === monthDate.getFullYear()
        )!.date);
};

const handleEditTabIndexZero = () => {
    if (props.dateType === dateTypeWeek && !props.nosTabSelected) {
        emit('dateSelectionSaved', selectedWeekDate.value);
    } else if (props.dateType === dateTypeMonth) {
        handleDateTypeMonth();
    } else if (checkNosDateSelection(date.value ?? '', nosValidTo.value ?? '')) {
        emit('dateSelectionSaved', date.value ?? '', nosValidTo.value);
    }

    if (!props.nosTabSelected) {
        updateLocalStorageDeliveryDate();
    }
};

const buttonClicked = (index: number) => {
    if (index === 0) {
        emit('dateSelectionClosed');

        return;
    }

    editTabIndex.value === 0 ? handleEditTabIndexZero() : emit('dateSelectionDelete', props.initialDate!);
    if (!props.nosTabSelected && editTabIndex.value !== 0) {
        updateLocalStorageDeliveryDate();
    }
};

onMounted(() => {
    if (props.dateType === dateTypeWeek) {
        deletingDate.value = selectedWeekDate.value;
    } else {
        deletingDate.value = date.value ?? '';
    }

    opened.value = true;
});

watch(() => editTabIndex.value, (newValue) => {
    if (newValue === 1) {
        selectedWeekDate.value = deletingDate.value;
    }
});
</script>

<style lang="scss" scoped>
.mx-datepicker-main {
    z-index: 99999999;
}

.mx-datepicker {
    width: rem(268);

    @include helper-font-size(6);
    @include helper-font-line-height(4);
    @include helper-font-weight(regular);
}

:deep(.mx-datepicker) {
    input {
        height: rem(48);
        padding: sp(s);
    }

    svg {
        @include helper-color(corporate-blue);

        height: rem(24);
        width: rem(24);
        margin-right: sp(xs);
    }
}

.modal-box {
    &__deadline {
        display: flex;
        align-items: flex-start;
    }

    &__deadline-text {
        margin-right: sp(xs);
    }

    &__colors-wrapper {
        @include helper-color-bg(light-gray);

        padding: rem(10);
        max-height: rem(100);
        width: 100%;
        overflow: auto;
    }

    &__colors-title {
        margin-bottom: sp(xs);
    }

    &__colors {
        @include helper-color(text-secondary);
        @include helper-font-size(default);
    }

    &__edit-warning {
        margin: sp(xs) 0;

        @include helper-color(alert-danger);
        @include helper-font-weight(bold);
    }

    select {
        margin: sp(xs) 0;
        min-height: rem(40);
        min-width: rem(100);

        @include helper-border();
        @include helper-border-radius(rem(5));
    }

    &__title {
        margin-bottom: sp(s);

        @include helper-font-size(2);
        @include helper-font-line-height(2);
        @include helper-font-weight(medium);
    }

    &__text {
        @include helper-font-size(5);
        @include helper-font-line-height(3);
        @include helper-font-weight(medium);
    }
}

.tabs {
    margin: sp(s) 0;
}

.date-modal :deep(.modal-box__custom-content) {
    min-height: 0;
    overflow: visible;
}

.date-modal {
    &__date-wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: sp(s) 0;
    }

    &__nos-date {
        gap: sp(s);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
    }

    &__disabled-selection {
        pointer-events: none;
        opacity: 0.6;
    }

    &__date,
    &__date-label {
        @include helper-font-size(6);
        @include helper-font-line-height(4);
        @include helper-font-weight(regular);
    }

    &__date {
        @include helper-font-weight(medium);
    }

    &__date-separator {
        height: rem(30);

        @include helper-border-r($setting-color-default-line);
    }

    &__save-date-wrapper {
        width: 100%;
    }

    &__save-date-line {
        margin: sp(m) 0;

        @include helper-border-t($setting-color-default-line);
    }

    &__save-date-header-wrapper {
        display: flex;
        align-items: center;
    }

    &__save-date-icon {
        margin-left: sp(xxs);

        @include helper-color(corporate-blue);
    }

    &__save-date-check-box {
        margin-top: sp(xs);
    }

    &__date-text {
        @include helper-font-size(6);
        @include helper-font-line-height(4);
        @include helper-font-weight(regular);
    }

    &__save-date-text {
        margin-bottom: sp(xxs);
    }
}

.select {
    margin: sp(s) 0;
}
</style>
