import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type {
    Fedas,
    FedasQueryParams,
    FedasResponse
} from '~/composables/types/api/searchDiscover/fedas';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getFedas(params: FedasQueryParams): PromiseResponseData<FedasResponse> {
    const url: string = `/abstract-products-memberfedas/${params.idProductAbstract}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi(url) as any;

        if (response.error) {
            return handleError(response);
        }

        const fedas: Fedas = {
            id: response.data.attributes.fedas,
            name: response.data.attributes.description
        };

        return handleSuccess<FedasResponse>({ fedas: fedas });
    } catch (error: any) {
        return handleError(error);
    }
}
