import getMatrix from '~/composables/api/searchDiscover/getMatrix';
import getMatrixNosItems from '~/composables/api/cartConditions/getMatrixNosItems';
import getMatrixCartItems from '~/composables/api/cartConditions/getMatrixCartItems';
import type {
    Branches,
    EnrichedMatrixDataSet,
    MatrixData,
    MatrixErrorResponse
} from '~/composables/types/api/searchDiscover/getMatrix';
import type { NavigationFailure, RouteLocationRaw } from 'vue-router';
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore';
import type {
    MatrixModuleItem,
    MatrixOrderItem
} from "~/composables/types/api/cartConditions/matrix";

export default function useMatrixDataLoader(cartUuid: Ref<string | null>) {
    const matrixFailedResponse: MatrixErrorResponse = {
        matrixError: undefined
    };
    let matrixData: MatrixData;
    const linkToOrder : Ref<undefined | Promise<NavigationFailure> | RouteLocationRaw> = ref();
    const { allLastCarts } = useCartsStore();
    const { $hasRole } = useNuxtApp();

    const mapPartnerIdsToBranches = (items: MatrixOrderItem[] | MatrixModuleItem[] | MatrixNosItem[]) => {
        items.map((item) => {
            if (!item.branchId && item.partnerId && item.partnerId !== '') {
                const branchId = matrixData.branches.find((branch) => branch.partnerId === item.partnerId)?.label ?? '';

                return { ...item, branchId: branchId };
            }

            if (!item.partnerId) {
                return { ...item, partnerId: ''};
            }

            return item;
        })
    }

    const mapPartnerIdsToItems = () => {
        mapPartnerIdsToBranches(matrixData.items.orderItems);
        mapPartnerIdsToBranches(matrixData.items.moduleItems);
        mapPartnerIdsToBranches(matrixData.items.nosItems);
    }

    const mapMissingBranchesWithQuantities = (): string[] => {
        if ($hasRole(RoleTypes.SUPPLIER)) {
            matrixData.branches = [];
        }

        const existingBranches = new Set(matrixData.branches.map((branch: Branches) => (branch.label)));
        const newItemBranches = new Set(matrixData.items.orderItems.map(item => item.branchId));
        const newModuleBranches = new Set(matrixData.items.orderItems.map(item => item.branchId));

        return [...new Set([...existingBranches, ...newItemBranches, ...newModuleBranches])];
    };

    const loadMatrixCartItems = async()=> {
        const items = await getMatrixCartItems(matrixData, cartUuid.value);

        const missingBranches = mapMissingBranchesWithQuantities();

        if (items.state === 'success') {
            await nextTick(() => {
                matrixData.items.loaded = true;
                matrixData.items.cartClosed = items.cartClosed ?? true;
                matrixData.items.userFlowState = items.userFlowState ?? '';
                matrixData.items.branchCluster = items.branchCluster ?? null;
                matrixData.items.orderItems = items.orderItems ?? [];
                matrixData.items.moduleItems = items.moduleItems ?? [];
                matrixData.items.modulName = items.modulName ?? null;
                matrixData.items.allocationId = items.allocationId ?? null;
                matrixData.branches.forEach((branch, index) => {
                    branch.label = missingBranches[index];
                });
                useMatrixPricesStore().setCartOrderItems(items.orderItems);
            });
        }
    };

    const loadMatrixData = async(abstractIdOrSku: number | string): Promise<boolean> => {
        const matrixResponseData = await getMatrix(abstractIdOrSku);

        if (matrixResponseData.state === 'failed') {
            matrixFailedResponse.matrixError = matrixResponseData;

            if (cartUuid.value && !$hasRole(RoleTypes.SUPPLIER) && matrixResponseData.status === ResponseStatusTypes.NOT_FOUND) {
                const currentCart = allLastCarts.find(cart => cartUuid.value === cart.id);

                if (currentCart?.state === 'CLOSED') {
                    linkToOrder.value = currentCart.name;

                    return false;
                }
            }

            return false;
        }

        const { ...matrixDataWithoutState } = matrixResponseData;
        matrixData = matrixDataWithoutState;

        await nextTick(() => {
            matrixData.items.loaded = false;
        });

        await loadMatrixCartItems();

        if (matrixData.nosTabVisible) {
            const nosItems = await getMatrixNosItems(matrixData);

            if (nosItems.state === 'success') {
                await nextTick(() => {
                    matrixData.items.nosLoaded = true;
                    matrixData.items.nosItems = nosItems.nosItems;
                });
            }
        }
        mapPartnerIdsToItems();

        return true;
    };

    const getResponseData = (): EnrichedMatrixDataSet => ({
        ...matrixData,
        ...matrixFailedResponse
    });

    return {
        loadMatrixData,
        loadMatrixCartItems,
        getResponseData,
        linkToOrder,
    };
}
