<template>
    <div class="prod-info">
        <div
            :class="{'prod-info__image': true, 'prod-info__image-cursor-default': matrixDisabled}"
            @click="redirectToPdp"
        >
            <img :src="imageUrl && imageUrl !== '' ? imageUrl : defaultImage" :alt="modelName">
            <div
                v-if="LOTS.includes(productType) || DISPLAYS.includes(productType)"
                class="prod-info__overlay"
            >
                <AtomLotDisplayOverlay
                    :product-type="productType"
                    :customer-group-access="customerGroupAccess || !$can(PermissionTypes.ORDER)"
                />
            </div>
        </div>
        <div class="prod-info__wrapper">
            <div class="prod-info__top">
                <div v-if="!$hasRole(RoleTypes.SUPPLIER)" class="prod-info__in-cart-icon">
                    <model-in-cart-icon
                        :key="microFrontendComponentKey"
                        :api-base-url="config.public.ccApiCartServiceUrl"
                        :auth-token="accessToken"
                        :brand-code="brandId"
                        :catalog-id="catalogId"
                        :model-code="modelCode"
                    />
                </div>
                <div v-if="!$hasRole(RoleTypes.SUPPLIER) && useStore().isVoStore()" class="prod-info__sms-icon">
                    <model-in-sortiment-icon
                        :key="microFrontendComponentKey"
                        :api-base-url="config.public.ccApiCartServiceUrl"
                        :auth-token="accessToken"
                        :brand-code="brandId"
                        :catalog-id="catalogId"
                        :model-code="modelCode"
                    />
                </div>
                <div
                    v-if="brand"
                    :title="$t('Plp.brand_title') + ' ' + brand"
                    class="prod-info__top-brand prod-info__top-label"
                >
                    {{ brand }}
                </div>
                <AtomProductLabel
                    v-for="label in (labels.filter((filteredLabel: string) => filteredLabel !== 'NETTO'))"
                    :key="label"
                    class="label"
                    :text="label"
                />
            </div>

            <div
                class="prod-info__name"
                :class="{'prod-info__name': true, 'prod-info__name-cursor-default': matrixDisabled}"
                :title="modelName"
                @click="redirectToPdp"
            >
                <span>{{`${modelName}`}}</span>
            </div>

            <div class="prod-info__bottom">
                <div class="prod-info__bottom-model-nr">
                    <span>{{$t('Product.model_number')}}</span>
                    <span>{{modelCode}}</span>
                </div>
                <div class="prod-info__bottom-previous-prod">
                    <MoleculeProductRelations
                        :product-abstract-relations="productAbstractRelations"
                    />
                </div>
                <div v-if="pgFedas4Name" class="prod-info__bottom-separator" />
                <div v-if="pgFedas4Name" class="prod-info__bottom-info">
                    <span>{{ $t('Product.product_groups' )}} </span>
                    <span>{{ pgFedas4Name }}</span>
                </div>

                <div v-if="matrixFedas" class="prod-info__bottom-separator" />
                <div v-if="matrixFedas" class="prod-info__bottom-info">
                    <div>
                        <span>{{ $t('Product.fedas') }}: </span>
                        <span>{{ matrixFedas }} {{ fedasName }}</span>
                    </div>
                    <div v-role:not="RoleTypes.SUPPLIER" class="prod-info__button" @click="openFedasModal">
                        <AtomButton
                            type="tertiary"
                            icon="edit"
                            size="xs"
                            :title="$t('Product.fedas')"
                        />
                        <div
                            v-if="matrixFedas !== pgFedas4"
                            class="prod-info__button--check-icon"
                        >
                            <AtomButton
                                class="prod-info__button--fa-check"
                                type="tertiary"
                                size="s"
                                :circular="true"
                                icon="check"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <AtomModal
            class="fedas__modal"
            :open="!!modalOpen"
            :buttons="fedasModalButtons"
            :prevent-background-scroll="true"
            :button-disabled="buttonDisabled"
            :show-x-button="false"
            @button-clicked="buttonClicked"
        >
            <div class="fedas__modal-title">{{$t('Product.add_fedas_title')}}</div>
            <AtomSearchInput
                v-model="searchTerm"
                class="fedas__modal-search"
                :placeholder="$t('Product.search_fedas_placeholder')"
                @update:model-value="searchTerm = $event"
            />
            <div class="fedas__modal-list">
                <ul>
                    <li v-for="item in filteredList" :key="item.id" @click="handleFedasClick(item.id)">
                        <input :id="item.id" type="radio" :value="item" :checked="item.id === selectedFedas" >
                        <label :for="item.id">{{ item.id }} - {{ item.name }}</label>
                    </li>
                </ul>
            </div>
            <div class="fedas__modal-reset">
                <AtomButton type="tertiary" icon="refresh-ccw" size="m" :text="$t('Product.reset_fedas')" @click="resetToOriginalFedas" />
            </div>
        </AtomModal>
    </div>
</template>
<script setup lang="ts">
import type { ProductAbstractRelations } from '~/composables/types/api/searchDiscover/catalogSearch';
import type { ModalButton } from '~/composables/types/ui';
import { DISPLAYS, LOTS } from '~/composables/utils/applicationConstants';
import { RoleTypes } from '~/composables/types/roleTypes';
import { PermissionTypes } from '~/composables/types/permissionTypes';
import useStore from "~/composables/service/useStore";

const props = defineProps<{
    isMatrixModal: boolean,
    modelName: string,
    modelCode: string,
    brandId: string,
    catalogId: string,
    brand: string,
    labels: string[],
    sku: string,
    imageUrl: string,
    productAbstractRelations: ProductAbstractRelations | null,
    pgFedas4Name: string | null,
    pgFedas4: string,
    hgg: string,
    pgFedasName: string,
    productAbstractId: string,
    productType: string,
    customerGroupAccess: boolean,
    matrixDisabled: boolean,
}>();

const emit = defineEmits<{
    (e: 'close:closeMatrix'): void,
}>();

const {
    loadFedas,
    loadFedasList,
    addOriginalFedas,
    fedasUpdate,
    fedasDelete,
    fedasList,
    fedas
} = useFedas(props.pgFedas4, props.pgFedasName);

const {
    setComponentKey,
    microFrontendComponentKey,
} = useProductsUtils();

const { $can, $emitter, $t, $hasRole } = useNuxtApp();
const router = useRouter();
const defaultImage = '/images/default-image.png';
const { getAccessToken } = ccMicroFrontend();
const accessToken = getAccessToken();
const config = useRuntimeConfig();

const modalOpen: Ref<boolean> = ref(false);
const matrixFedas = ref(props.pgFedas4);
const fedasName = ref(props.pgFedasName);
const searchTerm = ref('');
const selectedFedas = ref(matrixFedas.value);

const buttonDisabled = ref(false);

const handleFedasClick = (fedasId: string) => {
    selectedFedas.value = fedasId;
};

const filteredList = computed(() => fedasList.value?.filter(
    (item: { id: string, name: string }) => item.name.toLowerCase().includes(searchTerm.value.toLowerCase()) ||
        item.id.toLowerCase().includes(searchTerm.value.toLowerCase())
));

const redirectToPdp = async(e: MouseEvent) => {
    if (!props.sku || props.sku === '' || props.matrixDisabled) {
        return;
    }

    emit('close:closeMatrix');
    const link = `${NuxtLinkNameTypes.PRODUCT}/${props.sku}`;

    if (e.ctrlKey || e.metaKey) {
        window.open(link, '_blank');

        return;
    }

    await router.push(link);
};

const openFedasModal = async() => {
    await loadFedasList({ hgg: props.hgg });
    addOriginalFedas(props.pgFedas4, props.pgFedasName, matrixFedas.value);

    selectedFedas.value = matrixFedas.value;
    searchTerm.value = '';
    buttonDisabled.value = false;
    modalOpen.value = true;
};

const buttonClicked = async(index: number) => {
    if (index === 1 && selectedFedas.value) {
        buttonDisabled.value = true;

        const selectedFedasItem = filteredList.value.find(item => item.id === selectedFedas.value);

        let updateSuccessful: boolean;

        if (selectedFedas.value !== props.pgFedas4) {
            updateSuccessful = await fedasUpdate({ fedas: selectedFedas.value, idProductAbstract: Number(props.productAbstractId) });
        } else {
            updateSuccessful = await fedasDelete(Number(props.productAbstractId));
        }

        if (updateSuccessful) {
            matrixFedas.value = selectedFedas.value;
            fedasName.value = selectedFedasItem ? selectedFedasItem.name : '';
        }
    }

    modalOpen.value = false;
    buttonDisabled.value = false;
};

const resetToOriginalFedas = () => {
    selectedFedas.value = props.pgFedas4;
};

const fedasModalButtons: ModalButton[] = [
    {
        event: 'close',
        text: $t('General.cancel'),
        type: 'secondary',
    },
    {
        event: 'save',
        text: $t('Product.save_fedas'),
        type: 'primary',
    }
];

onMounted(async() => {
    $emitter.$on('onMatrixQuantitiesUpdated', setComponentKey);
    if (!$hasRole(RoleTypes.SUPPLIER)) {
        await loadFedas(props.productAbstractId);
    }
    matrixFedas.value = fedas.value?.id ?? props.pgFedas4;

    if (matrixFedas.value !== props.pgFedas4) {
        selectedFedas.value = matrixFedas.value;
        fedasName.value = fedas.value?.name ?? '';
    }
});

onUnmounted(() => {
    $emitter.$off('onMatrixQuantitiesUpdated', setComponentKey);
});
</script>
<style scoped lang="scss">
.prod-info {
    display: flex;
    flex-grow: 3;
    gap: sp(s);
    align-items: center;

    &__overlay {
        width: rem(70);
        height: rem(70);
        position: absolute;
        background: rgb(0 0 0 / 30%);

        :deep(.tile-overlay__text) {
            height: 100%;
            font-size: rem(15);
        }

        :deep(.tile-overlay__subtext) {
            display: none;
        }
    }

    &__image {
        cursor: pointer;
        width: rem(70);
        height: rem(70);
        overflow: hidden;

        @include helper-flex-centered();
        @include helper-border-radius(rem(9));
    }

    &__image-cursor-default {
        cursor: default;
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__top {
        display: flex;
        gap: sp(xxs);
        align-items: center;

        &-brand {
            @include helper-color-bg(gray-6);
            @include helper-color(white);
            @include helper-font-weight(medium);
        }

        &-label {
            @include helper-border-radius(rem(16));
            @include helper-font-line-height(5);
            @include helper-font-size(smaller);

            padding: sp(xxs) sp(xs);
        }
    }

    &__in-cart-icon {
        margin-top: rem(1);
    }

    &__items-in-cart {
        margin-left: 0;
    }

    &__name {
        max-width: fit-content;
        cursor: pointer;

        @include helper-color(text-title);
        @include helper-font-size(5);
        @include helper-font-line-height(3);
        @include helper-font-weight(bold);
        @include helper-ellipse-dots();

        &:hover:not(.disabled) {
            text-decoration: underline;
        }
    }

    &__name-cursor-default {
        cursor: default;
    }

    &__bottom {
        display: flex;
        flex-flow: row wrap;

        @include helper-font-size(small);
        @include helper-font-line-height(5);

        &-model-nr {
            display: flex;
            gap: sp(xxs);

            @include helper-color(text-title);
            @include helper-font-weight(bold);
        }

        &-previous-prod {
            @include helper-color(text-secondary);
            @include helper-font-weight(regular);
        }

        &-info {
            display: flex;
            gap: sp(xxs);

            & div {
                white-space: nowrap;
                max-width: rem(200);

                @include helper-ellipse-dots();
            }
        }

        &-separator {
            width: rem(1);
            height: rem(15);
            margin: 0 sp(xxs);

            @include helper-border-r($setting-color-default-line, rem(1));
        }
    }

    &__button {
        position: relative;

        &--check-icon {
            position: absolute;
            bottom: rem(-4);
            right: rem(-7);
            width: rem(14);
            height: rem(14);

            @include helper-color-bg(bright-blue);
            @include helper-border-radius(50%);
            @include helper-flex-centered();
        }

        &--fa-check {
            stroke-width: rem(2);
            position: absolute;

            :deep(.svg-icon) {
                width: rem(12);

                @include helper-color(white);
            }
        }
    }
}

.fedas {
    &__modal {
        display: flex;
        flex-direction: column;

        :deep(.modal-box) {
            max-width: 90%;
            max-height: 90%;
            overflow-y: auto;
        }

        :deep(.modal-box__custom-content) {
            display: flex;
            flex-direction: column;
            max-height: rem(600);
            overflow-y: auto;
        }

        :deep(.modal-box__buttons) {
            margin-top: rem(4);
        }

        &-title {
            @include helper-font-size(2);
            @include helper-font-line-height(2);
            @include helper-font-weight(medium);
        }

        &-search {
            width: 100%;
            margin: sp(s) 0 sp(xs);
            transition: border-color 0.2s ease;

            @include helper-border($setting-color-input-border, rem(1), solid);
            @include helper-border-radius($setting-border-radius-input);

            &:focus-within {
                outline: none;
                border-color: $setting-color-corporate-blue;
            }

            :deep(input::placeholder) {
                @include helper-color(text-secondary);
            }

            &:deep(.search__form) {
                padding: rem(4) sp(xs);

                @include helper-reset();
            }
        }

        &-list {
            width: 100%;
            height: rem(240);
            overflow-y: auto;
            padding: sp(xs);

            @include helper-border($setting-color-gray-1, rem(1), solid);
            @include helper-border-radius($setting-border-radius-input);

            & ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
            }

            & li {
                cursor: pointer;
                padding: sp(xs);
                display: flex;
                align-items: center;

                &:hover {
                    background-color: $setting-color-gray-1;
                }
            }

            & input[type='radio'] {
                margin-right: rem(5);
                accent-color: $setting-color-corporate-blue;
            }
        }

        &-reset {
            text-align: right;
            width: 100%;
        }
    }
}

.disabled {
    opacity: 0.75;
    cursor: not-allowed;
}
</style>
