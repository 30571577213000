
import getAdditionalOrderFields from '~/composables/api/searchDiscover/getAdditionalOrderFields';
import type {
    FieldValuesParams
} from '~/composables/api/cartConditions/getAdditionalOrderFieldsValues';
import getAdditionalOrderFieldsValues from '~/composables/api/cartConditions/getAdditionalOrderFieldsValues';
import updateAdditionalOrderFields from '~/composables/api/cartConditions/updateAdditionalOrderFields';
import useLocalStorageCache from '~/composables/useLocalStorageCache';
import type {
    AdditionalOrderFieldGroup,
    AdditionalOrderFieldRequestParams,
    AdditionalOrderFieldValues
} from '../types/api/searchDiscover';

export default function useAdditionalOrderFields() {
    const {
        getCachedData,
        updateCachedData,
    } = useLocalStorageCache<AdditionalOrderFieldGroup[]>(LocalStorageNameTypes.ORDER_FIELDS_KEY);

    const fieldGroups = shallowRef<AdditionalOrderFieldGroup[]>([]);
    const fieldValues = shallowRef<AdditionalOrderFieldValues>({});

    const brandCode = shallowRef('');
    const modelCode = shallowRef('');

    const loadAdditionalOrderFieldsData = async(): Promise<boolean> => {
        const response = await getAdditionalOrderFields();
        if (response.error) {
            return false;
        }
        if ('groups' in response) {
            fieldGroups.value = response.groups;
        }
        updateCachedData(fieldGroups.value);

        return true;
    };

    const loadAdditionalOrderFields = async(): Promise<boolean> => {
        const groups = getCachedData();
        if (!groups) {
            const success = await loadAdditionalOrderFieldsData();
            if (!success) {
                return false;
            }
        } else {
            fieldGroups.value = groups;
        }

        return true;
    };

    const loadFieldValues = async(brand:string, model:string): Promise<boolean> => {
        brandCode.value = brand;
        modelCode.value = model;

        if (Object.keys(fieldValues.value).length) {
            return true;
        }

        const requestParams: FieldValuesParams = {
            brandCode: brand,
            modelCode: model,
        };

        const response = await getAdditionalOrderFieldsValues(requestParams);
        if (response.error) {
            return false;
        }
        if ('fields' in response) {
             
            fieldValues.value = response.fields;
        }

        return true;
    };

    const updateAdditionalOrderFieldValues = async(fields: any): Promise<boolean> => {
        const params: Omit<AdditionalOrderFieldRequestParams, 'cartId'> = {
            brandCode: '',
            modelCode: '',
            fields: []
        };

        fieldValues.value = { ...fields };

        for (const [key, value] of Object.entries(fields)) {
            if (typeof (value) === 'boolean') {
                fields[key] = (value ? '1' : '0');
            } else if (typeof (value) === 'number') {
                fields[key] = `${value}`;
            } else {
                fields[key] = (value ? value.toString() : '');
            }
        }

        params.fields = fields;
        params.brandCode = brandCode.value;
        params.modelCode = modelCode.value;

        const additionalOrderFields = await updateAdditionalOrderFields(params);

        return !additionalOrderFields.error;
    };

    const getFieldValueOfId = (field: any) => fieldValues.value[`${field.fieldId}_${field.fieldsetId}`];

    const parseWithExactness = (floatValue: string, exactness: number) => {
        if (exactness > 0) {
            const parsedValue = parseFloat(floatValue.replace(',', '.'));

            return !isNaN(parsedValue) ? parsedValue.toFixed(exactness).toString() : '';
        }

        return floatValue;
    };

     
    const getFieldValues = (): AdditionalOrderFieldValues => {
        const result: AdditionalOrderFieldValues = {};

        for (const group of fieldGroups.value) {
            for (const field of group.fields) {
                const fieldKey = `${field.fieldId}_${field.fieldsetId}`;
                const fieldValue = getFieldValueOfId(field);
                switch (field.type) {
                case 'string':
                case 'text':
                    result[fieldKey] = fieldValue ?? '';
                    break;
                case 'select':
                    result[fieldKey] = fieldValue === undefined ? undefined : `${fieldValue}`;
                    break;
                case 'float':
                case 'int':
                    if (typeof fieldValue === 'number') {
                        result[fieldKey] = fieldValue;
                    } else if (typeof fieldValue === 'string') {
                        if (fieldValue === '') {
                            result[fieldKey] = undefined;
                        } else if (field.type === 'float') {
                            result[fieldKey] = parseWithExactness(fieldValue, field.exactness);
                        } else {
                            result[fieldKey] = parseInt(fieldValue, 10);
                        }
                    } else {
                        result[fieldKey] = undefined;
                    }
                    break;
                case 'boolean':
                    if (typeof fieldValue === 'undefined') {
                        result[fieldKey] = undefined;
                    } else if (typeof fieldValue === 'boolean') {
                        result[fieldKey] = fieldValue;
                    } else {
                        result[fieldKey] = fieldValue !== '0';
                    }
                    break;
                default:
                    console.warn('unknown type', field.type);
                }
            }
        }
        fieldValues.value = { ...result };

        return fieldValues.value;
    };

    const getAdditionalOrderFieldGroups = (): AdditionalOrderFieldGroup[] => fieldGroups.value;

    return {
        loadAdditionalOrderFields,
        loadFieldValues,
        getAdditionalOrderFieldGroups,
        getFieldValues,
        updateAdditionalOrderFieldValues,
        getFieldValueOfId,
        loadAdditionalOrderFieldsData,
    };
}
