import type { ApiGenericErrorResponse, PromiseResponseData } from '~/composables/types/api/apiResponse';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { NetPricesRequest } from '~/composables/types/api/cartConditions/netPrices';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function updateNetPrices(params: NetPricesRequest)
    : PromiseResponseData<object> {
    const { fetchCcCartApi } = fetchOrderDetermination();

    try {
        const response = <any> await fetchCcCartApi(`/netto-price`, {
            method: 'PUT',
            body: [...params]
        });

        if (response?.error) {
            return handleError(response);
        }

        return handleSuccess<object>({});
    } catch (error: any) {
        return handleError(<ApiGenericErrorResponse>error);
    }
}
