<template>
    <div class="cart-flyout-button">
        <li
            class="header-action__cart-flyout-button"
            @pointerenter="!isTouchDevice() && pointerEnterEvent()"
            @pointerleave="!isTouchDevice() && pointerLeaveEvent()"
            @click="onMatrixClick"
        >
            <AtomButton v-if="!props.showInMatrix" class="header__action" type="tertiary" size="reset" @click="navigateToCarts()">
                <AtomIcon class="header__icon" name="basket" width="20" />
                <AtomIcon class="arrow-down-icon header__icon--dropdown-arrow" name="chevron-down" width="20"/>
                <span class="header__action__text">{{ $t('Cart.shopping_carts') }}</span>
            </AtomButton>

            <AtomButton
                v-if="props.showInMatrix"
                class="header__action"
                type="tertiary"
                size="reset"
                :title="currentCartName"
            >
                <AtomTextInput
                    size=""
                    icon="chevron-down"
                    :model-value="currentCartName"
                    :disabled="true"
                    :allow-pointer-events-from-parent="true"/>
            </AtomButton>

            <AtomFlyout
                class="cart-flyout"
                :model-value="flyoutOpen"
                :close-on-outside-click="isTouchDevice()"
                @update:model-value="flyoutOpen = $event"
            >
                <div>
                    <div class="flyout-container__sub-nav-inner">
                        <div class="flyout-container__sub-nav-inner-list">
                            <div
                                 class="flyout-container__sub-nav-title-container"
                                 @click='goToMultiCartPage()'>
                                <AtomIcon class="header__icon" name="basket" width="24"/>
                                <h4 class="flyout-container__sub-nav-title">
                                    {{ $t('Cart.shopping_carts') }}
                                </h4>
                            </div>

                            <div
class="flyout-container__sub-nav-title-container
                                flyout-container__sub-nav-info-title-container">
                                <h4
class="flyout-container__sub-nav-title
                                   flyout-container__sub-nav-info-title">
                                    {{ $t('Cart.cart_info').toUpperCase() }}
                                </h4>
                            </div>

                            <ul v-if="customCarts && customCarts.length" class="flyout-container__list-items">
                                <li
                                    v-for="(cart, index) in customCarts"
                                    :key="`cart-header-${index}`"
                                    :class="{
                                        'flyout-container__list-items_item_channeling': channeling,
                                    }"
                                    class="flyout-container__list-items_item flyout-container--default-cart-item"
                                    @click="onCartClick(cart)"
                                >
                                    <div class="col__info">
                                        <h5 class="col__info-upper">
                                            <AtomIcon
                                                v-if="channeling"
                                                class="col__info-lock"
                                                name="lock-2"
                                                width="16"
                                            />
                                            <span class="col__info-cart-name" :title="cart.name">
                                                {{ cart.name }}
                                            </span>
                                        </h5>
                                        <div class="col__info-upper col__info-lower">
                                            <div class="col__info-section col__info-quantity">
                                                <span class="col__info-primary cy-quantity">{{ cart.quantitySum }}</span>
                                                <span class="col__info-secondary">{{ $t('Cart.pieces') }}</span>
                                            </div>
                                            <div class="col__info-separator"/>
                                            <div class="col__info-section col__info-price-net-ek">
                                                <span class="col__info-primary cy-sum-ek">{{ useMoneyFormat(cart.netEk) }}</span>
                                                <span class="col__info-secondary">{{ $t('Cart.net_ek') }}</span>
                                            </div>
                                            <div class="col__info-separator"/>
                                            <div class="col__info-section col__info-price">
                                                <span class="col__info-primary cy-sum-upe">{{ useMoneyFormat(cart.sumUpe) }}</span>
                                                <span class="col__info-secondary">{{ $t('Cart.upe') }}</span>
                                            </div>
                                            <div class="col__info-separator"/>
                                            <div class="col__info-section col__info-price-margin">
                                                <span class="col__info-primary cy-trader-margin">
                                                    {{ useCalculateTradingMargin(cart.netEk, cart.sumUpe) }}
                                                </span>
                                                <span class="col__info-secondary">{{ $t('Cart.percent_from') }}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col__separator col__separator-last"/>

                                    <div class="col__buttons">
                                        <div
                                            class="col__button--status"
                                            :class="`is-${(cart.userFlowCartState ?? 'NEW').toLowerCase()}`"
                                            :title="$t(`Cart.status_${(cart.userFlowCartState ?? 'NEW').toLowerCase()}`)"
                                        >
                                            <AtomIcon
                                                :name="statusIcons[cart.userFlowCartState ?? 'NEW']"
                                                :bolding="2"
                                            />
                                        </div>

                                        <AtomButton
                                            type="primary"
                                            icon="arrow-right"
                                            size="m"
                                            class="spacing-left--xs cy-navigate-to-cart"
                                        />
                                    </div>
                                </li>
                            </ul>

                            <div v-else class="flyout-container">
                                <AtomSpinner />
                            </div>
                        </div>
                    </div>
                    <div class="flyout-container__sub-nav-actions">
                        <div class="flyout-container__sub-nav-actions-separator"/>
                        <div
                            class="flyout-container__sub-nav-actions-buttons
                            grid grid--gap grid--justify grid--nowrap-lg-only"
                        >
                            <div class="col">
                                <AtomButton
                                    v-role:not="RoleTypes.SUPPLIER"
                                    class="flyout-container__sub-nav-actions-new-cart button-icon"
                                    type="tertiary"
                                    size="l"
                                    icon="plus-circle"
                                    :text="$t('Cart.create_new_cart')"
                                    @click='openCreateCartModal'
                                />
                            </div>
                            <div class="col">
                                <AtomButton
                                    class="button-icon cy-carts-button"
                                    type="secondary"
                                    size="m"
                                    icon="basket"
                                    :text="$t('Cart.all_carts')"
                                    @click="goToMultiCartPage()"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </AtomFlyout>
        </li>
        <AtomModal
            :open='!!createCartOpen'
            @button-clicked='closeCreateCartModal'
            @update:open="closeCreateCartModal"
        >
            <div class="create-cart__container">
                <h2 class="create-cart__title-text">{{ $t('General.create_new_cart') }}</h2>
                <form class="create-cart__buttons" @submit.prevent="createNewCart()">
                    <AtomTextInput
                        v-model="newCartName"
                        name="name"
                        class="create-cart__input-element"
                        required
                        autofocus
                        :valid="nameBlur || isValidCartName(newCartName)"
                        :error-message="$t(errorMessage.CREATE)"
                        :placeholder="$t('Cart.input_name')"
                        @blur="nameBlur = false"
                    />
                    <AtomButton
                        submit
                        size="l"
                        :text="$t('Cart.create_shopping_cart')"
                    />
                </form>
            </div>
        </AtomModal>
    </div>
</template>

<script lang="ts" setup>
import type { CartMappedItem } from '~/composables/types/api/cartConditions/carts-abstract';
import { RoleTypes } from '~/composables/types/roleTypes';
import useCalculateTradingMargin from '~/composables/utils/useCalculateTradingMargin';
import useMoneyFormat from '~/composables/utils/useMoneyFormat';
import {isTouchDevice} from '~/composables/utils/useBrowserUtils';

const props = withDefaults(defineProps<{
    showInMatrix?: boolean,
    cartId?: string,
    isCartService?: boolean,
}>(), {
    showInMatrix: false,
    cartId: '',
    isCartService: false,
});

const {
    isValidCartName,
} = useCartsAbstracts();

const { $t, $emitter, $hasRole } = useNuxtApp();
const cartsStore = useCartsStore();
const {
    carts,
    lastCarts,
    activeCart,
    isLoading,
    errorMessage,
} = storeToRefs(cartsStore);
const toasts = useToasts();
const flyoutOpen = ref(false);
const refreshCarts = ref(true);
const mobileAvailablePxRatio = 768;
const unloadDelay = 3000;
let defaultReferenceWidth = 0;
let unloadTimeout: NodeJS.Timeout;
const channeling = ref(false);
const createCartOpen = ref(false);
const newCartName = ref('');
const nameBlur = ref(false);
const statusIcons = {
    NEW: 'star',
    EDIT: 'tool',
    DONE: 'check-circle',
};

const customCarts = computed(() => (props.showInMatrix ? carts.value : lastCarts.value));

const currentCartName = computed(() => {
    if (props.cartId) {
        return customCarts.value.find((cart : { id: string, name: string}) => cart.id === props.cartId)?.name ?? '';
    }

    return activeCart.value?.name ?? '';
});

const recalculateWindowWidth = () => {
    const timeout = 500;

    setTimeout(() => {
        defaultReferenceWidth = window.innerWidth;
    }, timeout);
};

if (typeof window !== 'undefined') {
    defaultReferenceWidth = window.innerWidth;
}

const pointerEnterEvent = () => {
    if (!props.showInMatrix) {
        const isModalAvailable = mobileAvailablePxRatio <= defaultReferenceWidth;

        if (!isModalAvailable) {
            return;
        }
        flyoutOpen.value = true;
    }
};

const pointerLeaveEvent = () => {
    flyoutOpen.value = false;
};

const onMatrixClick = () => {
    if (props.showInMatrix) {
        if (flyoutOpen.value) {
            flyoutOpen.value = false;

            return;
        }

        flyoutOpen.value = true;
    }
};

watch(flyoutOpen, async(new_value: boolean) => {
    if (new_value) {
        if (refreshCarts.value && !isLoading.value && !props.showInMatrix) {
            clearTimeout(unloadTimeout);
            await cartsStore.loadCarts();

            if (errorMessage.value.LOAD) {
                toasts.add('LOAD_ERROR');
            }


            refreshCarts.value = false;
        }
    } else {
        unloadTimeout = setTimeout(() => {
            refreshCarts.value = true;
        }, unloadDelay);
    }
});

const onCartClick = (cart: CartMappedItem) => {
    if (cart.state === 'OPEN') {
        if (props.showInMatrix) {
            cartsStore.updateSelectedCart(cart.id);
        }

        flyoutOpen.value = false;

        $emitter.$emit('updateCartId', {
            id: cart.id
        });
    }

    if (!props.showInMatrix) {
        navigateTo(`${NuxtLinkNameTypes.CART_PAGE}/${cart.id}`);
    }

    if (props.showInMatrix) {
        toasts.add({
            type: 'success',
            headline: $t('Cart.cart_selected'),
            text: $t('Cart.cart_selected_text', [cart.name]),
        });
    }
};

const goToMultiCartPage = async() => {
    await navigateTo(NuxtLinkNameTypes.CARTS_PAGE);
};

const closeCreateCartModal = () => {
    createCartOpen.value = false;
};
const openCreateCartModal = () => {
    createCartOpen.value = true;
    flyoutOpen.value = true;
    newCartName.value = '';
};
const createNewCart = async() => {
    if (newCartName.value) {
        await cartsStore.createCart(newCartName.value);

        if (!errorMessage.value.CREATE) {
            closeCreateCartModal();
            cartsStore.updateSelectedCart(null);

            return;
        }

        toasts.add({
            type: 'error',
            headline: $t('General.load_error_title'),
            text: $t(errorMessage.value.CREATE),
        });
    }
};

const navigateToCarts = async() => {
    if (isTouchDevice()) {
        flyoutOpen.value = !flyoutOpen.value;

        return;
    }

    await goToMultiCartPage();
    flyoutOpen.value = false;
};

onMounted(async() => {
    if (!$hasRole(RoleTypes.SUPPLIER)) {
        await cartsStore.loadCarts();
    }

    window.addEventListener('resize', recalculateWindowWidth);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', recalculateWindowWidth);
});
</script>

<style lang="scss" scoped>
.cart-flyout :deep(.flyout) {
    padding: rem(16) rem(5) rem(24) rem(16);
}

.cart-flyout-button {
    display: inline-block;
    margin-left: rem(14);
    margin-right: rem(14);
}

.header-action__cart-flyout-button {
    position: relative;
}

.col {
    &__cart-name {
        font-size: fs(smaller);
        font-weight: fw(light);
        text-transform: uppercase;

        @include helper-color(text-secondary);
        @include helper-font-line-height(5);
    }

    &__cart-api-name {
        width: rem(150);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;

        @include helper-color(text-title);
    }

    &__cart-api-sum {
        @include helper-color(text-title);
    }

    &__separator {
        height: rem(48);

        @include helper-border-r($setting-color-default-line);
    }

    &__separator-last {
        margin-left: auto;
    }

    &__info {
        flex: 1;
        margin: sp(xs) sp(m) sp(xs) sp(s);
        display: flex;
        flex-direction: column;

        &-upper {
            margin-bottom: rem(2);
            display: flex;
            align-items: center;

            @include helper-font-size(default);
            @include helper-font-line-height(4);
            @include helper-font-weight(medium);
            @include helper-color(text-title);
        }

        &-lower {
            margin-top: rem(2);
            margin-bottom: 0;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        &-cart-name {
            max-width: rem(600);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &-section {
            display: flex;
            align-items: flex-end;
        }

        &-primary {
            margin-right: sp(xxs);

            @include helper-font-weight(regular);
        }

        &-secondary {
            @include helper-font-size(smaller);
            @include helper-font-line-height(5);
            @include helper-font-weight(regular);
            @include helper-color(text-secondary);
        }

        &-quantity {
            min-width: rem(65);
        }

        &-price {
            min-width: rem(120);
            justify-content: flex-end;
        }

        &-price-margin {
            min-width: rem(50);
            justify-content: flex-end;
        }

        &-price-net-ek {
            min-width: rem(145);
            justify-content: flex-end;
        }

        &-separator {
            height: rem(20);
            margin: 0 sp(xs);

            @include helper-border-r($setting-color-default-line);
        }

        &-lock {
            margin-right: rem(4);
        }
    }

    &__buttons {
        padding: 0 sp(s);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button {
        &--status {
            width: rem(38);
            height: rem(40);

            @include helper-flex-centered();
            @include helper-border($setting-color-corporate-blue);
            @include helper-border-radius($setting-border-radius-1);

            &.is-new {
                @include helper-color(cart-status-new);
            }

            &.is-edit {
                @include helper-color(cart-status-edit);
            }

            &.is-done {
                @include helper-color(cart-status-done);
            }
        }
    }
}

.flyout-container {
    position: relative;
    min-width: fit-content;

    &__sub-nav-title-container {
        width: fit-content;
        cursor: pointer;
        margin-bottom: sp(s);
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &__sub-nav-info-title-container {
        margin-bottom: sp(xs);
    }

    &__sub-nav-title {
        background: $setting-color-white;
        color: $setting-color-dark-blue;
        padding-left: rem(5);

        @include helper-font-line-height(4);
        @include helper-font-weight(medium);
    }

    &__sub-nav-info-title {
        margin-right: sp(m);

        @include helper-color(text-secondary);
        @include helper-font-size(default);
    }

    &__sub-nav-inner {
        max-height: rem(350);
        overflow: hidden;
        overflow-y: auto;
    }

    &__sub-nav-inner-list {
        width: max-content;
        overflow: visible;
        box-sizing: border-box;
        padding-right: rem(14);
    }

    @-moz-document url-prefix() { /* stylelint-disable-line */
        &__sub-nav-inner-list {
            margin-right: rem(14);
        }
    }

    &__sub-nav-actions {
        height: rem(48);
        margin-top: rem(10);
        padding-right: rem(14);

        @include helper-svg-size(16);

        &-separator {
            margin-left: rem(-16);
            margin-right: rem(-19);

            @include helper-border-t($setting-color-default-line);
        }

        &-buttons {
            height: rem(72);
            align-items: center;
        }

        &-new-cart {
            padding: 0;
        }

        .user-navigation__action {
            display: flex;
            justify-content: center;
            align-items: center;

            @include helper-color(dark-blue);

            span {
                padding-left: rem(7);

                @include helper-font-line-height(4);
                @include helper-color(corporate-blue);
            }
        }
    }

    &__list-items {
        width: max-content;

        &_item {
            cursor: pointer;
            margin-bottom: rem(10);
            height: rem(64);
            width: max-content;
            min-width: 100%;
            display: flex;

            @include helper-border-radius(rem(10));

            flex-direction: row;
            align-items: center;

            @include helper-border($setting-color-gray-1);

            .select-box {
                min-width: rem(56);
                display: flex;
                justify-content: center;
                align-items: center;

                @include helper-svg-size(16);

                input {
                    display: none;
                }

                svg {
                    @include helper-color(corporate-blue);
                }
            }

            .thumbnail {
                font-weight: fw(regular);
                padding: 0 rem(16) 0 rem(8);
                min-width: rem(56);

                span {
                    font-size: fs(medium);

                    @include helper-color(text-title);
                }
            }

            button svg {
                @include helper-color(white);
            }
        }

        &_item_channeling {
            cursor: default;

            @include helper-color-bg(light-gray);
        }
    }

    &__open {
        transform: translateX(0) scale(1);
    }

    :deep(.spinner) {
        width: rem(380);
        margin: 0 auto;

        @include helper-color(gray-2);
    }
}

.flyout-container-loading {
    width: rem(462);
}

.header {
    svg {
        @include helper-color(corporate-blue);
    }

    &__action {
        color: $setting-color-corporate-blue;
        cursor: pointer;
        background-color: transparent;

        .svg-icon {
            line-height: 1;
        }

        &__text {
            display: block;
            font-size: map-get($setting-font-size, default);
            font-weight: map-get($setting-font-weight, regular);
            margin-top: 0.375rem;
            white-space: nowrap;
        }
    }

    &__icon {
        @include helper-color(corporate-blue);
    }
}

.create-cart {
    &__title-text {
        margin-bottom: sp(s);
    }

    &__input-element {
        margin-bottom: sp(s);
    }
}
</style>
