import {
    fetchOrderDetermination
} from '~/composables/api/apiFactories/fetchOrderDetermination';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { NetPricesQueryParams } from '~/composables/types/api/cartConditions/netPrices';
import useParamsToQuery from '~/composables/api/useParamsToQuery';
import type { PromiseResponseData } from '~/composables/types/api/apiResponse';

export default async function deleteNetPrices(params: NetPricesQueryParams):
    PromiseResponseData<object> {
    const url = `/netto-price?${useParamsToQuery(params)}`;
    const { fetchCcCartApi } = fetchOrderDetermination();

    try {
        const response = <any> await fetchCcCartApi(url, {
            method: 'DELETE',
        });

        if (response?.error) {
            return handleError(response);
        }

        return handleSuccess<object>({});
    } catch (error: any) {
        return handleError(error);
    }
}
