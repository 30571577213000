<template>
    <div :class="classNames">
        <input
            ref="input"
            :class="{'allow-parent-pointer-events': allowPointerEventsFromParent && disabled, error: errorMessage || inputWarning}"
            type="text"
            :value="modelValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :autofocus="autofocus"
            @input="onInput"
            @keydown="onKeydown"
        >
        <AtomIcon v-if="props.icon" class="input-icon" :name="props.icon" width="20"/>
        <div v-if="errorMessage" class="error-message">
            {{ errorMessage }}
        </div>
        <div v-else-if="inputWarning" class="error-message">
            {{ inputWarning }}
        </div>
    </div>
</template>

<script lang="ts" setup>
const { $t } = useNuxtApp();

const props = withDefaults(defineProps<{
    modelValue: string,
    errorMessage?: string,
    size?: string,
    maxLength?: number,
    placeholder?: string,
    disabled?: boolean,
    allowPointerEventsFromParent?: boolean,
    autofocus?: boolean,
    icon?: string,
}>(), {
    errorMessage: undefined,
    maxLength: undefined,
    icon: undefined,
    size: 'l',
    placeholder: '',
    disabled: false,
    allowPointerEventsFromParent: false,
});

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void,
}>();

const input = ref();
const classNames = computed(() => `${props.size} custom-text-input ${props.icon ? 'with-icon' : ''}`);
const inputWarning: Ref<string | null> = ref(null);

const onInput = (event: Event) => {
    const inputTarget = event.target as HTMLInputElement;
    let value = inputTarget.value;
    if (props.maxLength && inputTarget.value && inputTarget.value.length > props.maxLength) {
        value = value.substring(0, props.maxLength);
        inputTarget.value = value;
    }
    props.maxLength && value.length > props.maxLength ? inputWarning.value = $t('General.invalid_input') : inputWarning.value = null;

    emit('update:modelValue', value);
};

const onKeydown = (event: KeyboardEvent) => {
    if (props.maxLength && props.modelValue && props.modelValue.length === props.maxLength) {
        const ctrl = /mac/i.test(navigator.platform) ? event.metaKey : event.ctrlKey;

        if (!ctrl &&
            event.code !== 'Backspace' &&
            event.code !== 'Delete' &&
            event.code !== 'ArrowLeft' &&
            event.code !== 'ArrowRight' &&
            event.code !== 'ShiftLeft' &&
            event.code !== 'ShiftRight' &&
            event.code !== 'End' &&
            event.code !== 'Home' &&
            event.code !== 'Tab') {
            event.preventDefault();
        }
    }
};

const triggerFocus = () => {
    input.value.focus();
};

defineExpose({
    focus: triggerFocus,
});
</script>

<style lang="scss" scoped>
.custom-text-input {
    position: relative;

    input {
        width: 100%;
        height: 80%;
        appearance: textfield;
        padding: sp(xs);

        @include helper-border();
        @include helper-border-radius($setting-border-radius-input);
        @include helper-color(text-title);

        &[disabled].allow-parent-pointer-events {
            pointer-events: none;

            @include helper-ellipse-dots();
        }

        &:hover {
            border-color: $setting-color-state-hover;
        }

        &.error {
            @include helper-border($setting-color-alert-danger, rem(1));
        }
    }

    .error-message {
        margin-top: sp(xxs);

        @include helper-color(alert-danger);
        @include helper-font-size(smaller);
        @include helper-font-line-height(tight);
    }

    &.l {
        height: rem(60);
    }

    &.m {
        height: rem(52);
    }

    &.s {
        height: rem(40);
    }

    &.with-icon {
        input {
            padding-right: rem(40);
        }

        .input-icon {
            position: absolute;
            top: 50%;
            right: rem(12);
            transform: translateY(-50%);
            pointer-events: none;

            @include helper-color(corporate-blue);
        }
    }
}
</style>
