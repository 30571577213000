import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function deleteFedas(productId: number): PromiseResponseData<object> {
    const url = `/abstract-products-memberfedas/${productId}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = <any> await fetchSdApi(url, {
            method: 'DELETE',
        });

        if (response?.error) {
            return handleError(response);
        }

        return handleSuccess<object>({});
    } catch (error: any) {
        return handleError(error);
    }
}
