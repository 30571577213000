import handleError from '~/composables/api/handleResponse';
import type { ApiResponse } from '~/composables/types/api/apiResponse';
import type {
    AdditionalOrderField,
    AdditionalOrderFieldGroup,
    AdditionalOrderFieldResponse,
    AdditionalOrderFieldSelectOption,
} from '~/composables/types/api/searchDiscover';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getAdditionalOrderFields(): Promise<AdditionalOrderFieldResponse | ApiResponse> {
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi('/additional-order-field') as any;

        if (response.error) {
            return handleError<AdditionalOrderFieldResponse>(response);
        }

        const groups: AdditionalOrderFieldGroup[] = [];

        for (const erp of response.data[0].attributes.erps) {
            if (!erp.isActive) {
                continue;
            }

            const fields: AdditionalOrderField[] = [];

            for (const field of erp.additionalOrderFieldFieldGroups) {
                if (!field.isActive) {
                    continue;
                }

                const selectOptions: AdditionalOrderFieldSelectOption[] = [];

                for (const option of field.additionalOrderFieldFieldGroupToOptions) {
                    if (!option.isActive) {
                        continue;
                    }
                    selectOptions.push({
                        name: option.name,
                        value: option.idAdditionalOrderFieldFieldGroupToOption
                    });
                }

                if (field.type === 'select' && selectOptions.length === 0) {
                    continue;
                }

                switch (field.type) {
                case 'FLT':
                    field.type = 'float';
                    break;
                case 'INT':
                    field.type = 'int';
                    break;
                case 'BOL':
                    field.type = 'boolean';
                    break;
                case 'STR':
                    field.type = 'string';
                    break;
                case 'COD':
                    field.type = 'text';
                    break;
                case 'select':
                    field.type = 'select';
                    break;
                default:
                    console.error(`Unexpected field type ${field.type}`);
                    break;
                }

                const new_field = {
                    id: field.idAdditionalOrderFieldFieldGroup,
                    name: field.name,
                    required: field.isMustfield,
                    size: field.size ?? 0,
                    exactness: field.exactness ?? 0,
                    type: field.type,
                    inherited: field.isInherited,
                    selectOptions: selectOptions,
                    fieldId: field.fieldId,
                    fieldsetId: field.fieldsetId,
                };

                fields.push(new_field);
            }

            if (fields.length === 0) {
                continue;
            }

            const group = {
                id: erp.idErp,
                fieldsetId: erp.fieldsetId,
                name: erp.name,
                fields: fields,
            };

            groups.push(group);
        }

        return {
            groups: groups,
        };
    } catch (error) {
        return handleError(error);
    }
}
