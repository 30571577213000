<template>
    <div>
        <div
             v-if="opened"
             :class="{'flyout': true, 'visible': visible, 'left': arrowPosition === 'left'}"
             :style="{'top': margin + 'px'}"
             @click="preventPropagation"
             @pointerleave="pointerLeave"
             @pointerenter="pointerEnter"
        >
            <div v-if="closeOnOutsideClick" class="flyout__background" @click="closeImmediately"/>
            <div class="flyout__container" @click="preventPropagation">
                <slot />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
const closeOpenDelay = 250;
const hideDelay = 150;

export default {
    name: 'Flyout',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        arrowPosition: {
            type: String,
            default: 'right',
        },
        margin: {
            type: Number,
            default: 55,
        },
        closeOnOutsideClick: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['change', 'update:modelValue'],
    setup(props: any, { emit }) {
        const opened = ref(false);
        const visible = ref(false);

        let closeTimeout = 0;
        let openTimeout = 0;

        const preventPropagation = (event: Event) => {
            event.stopPropagation();
        };

        const preventDefaultAndPropagation = (event: Event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        const closeImmediately = () => {
            clearTimeout(closeTimeout);
            opened.value = false;
            visible.value = false;
            emit('change', false);
            closeTimeout = 0;
        };

        const closeDelayed = () => {
            window.clearTimeout(openTimeout);
            openTimeout = 0;
            if (closeTimeout || !opened.value) {
                return;
            }
            closeTimeout = window.setTimeout(() => {
                visible.value = false;
                closeTimeout = 0;
                window.setTimeout(() => {
                    closeImmediately();
                }, hideDelay);
            }, closeOpenDelay);
        };

        const openDelayed = () => {
            if (openTimeout || opened.value) {
                return;
            }
            window.clearTimeout(closeTimeout);
            closeTimeout = 0;

            openTimeout = window.setTimeout(() => {
                opened.value = true;
                openTimeout = 0;
            }, closeOpenDelay);
        };

        watch(() => props.modelValue, () => {
            if (props.modelValue) {
                openDelayed();
            } else {
                closeDelayed();
            }
        });

        watch(opened, (val) => {
            window.setTimeout(() => {
                visible.value = val;
            });

            if (val) {
                window.clearTimeout(closeTimeout);
                closeTimeout = 0;

                /*
                 * window.$nuxt.$emit(openEventName);
                 * window.$nuxt.$on(openEventName, closeImmediately);
                 */
            } else {
                // window.$nuxt.$off(openEventName, closeImmediately);
            }

            emit('update:modelValue', val);
        });

        const pointerLeave = () => {
            if (!props.closeOnOutsideClick) {
                closeDelayed();
            }
        };

        const pointerEnter = () => {
            clearTimeout(closeTimeout);
            closeTimeout = 0;
        };

        return {
            visible,
            opened,
            pointerLeave,
            pointerEnter,
            preventPropagation,
            preventDefaultAndPropagation,
            closeImmediately,
        };
    }
};
</script>

<style lang="scss" scoped>
.flyout {
    cursor: default;
    position: absolute;
    right: 0;
    top: 0;
    padding: rem(24);
    box-shadow: 0 rem(2) rem(6) $setting-color-black;
    z-index: $setting-zi-flyout;
    background: $setting-color-white;
    transition: opacity 0.15s;
    opacity: 0;

    @include helper-border-radius(rem(10));

    &.left {
        left: 0;
        right: auto;
    }

    &.visible {
        opacity: 1;
    }

    &__container {
        position: relative;
    }

    &::before {
        content: '';
        border: rem(1) solid;
        border-color: $setting-color-gray-2 transparent transparent $setting-color-gray-2;
        background: #fff;
        position: absolute;
        top: rem(-5.6);
        right: rem(30);
        width: rem(10);
        height: rem(10);
        transform: translateX(-50%) rotate(45deg);
    }

    &.left::before {
        left: rem(30);
    }

    &__background {
        position: fixed;
        inset: 0;
    }
}
</style>
