import getFedas from '~/composables/api/searchDiscover/getFedas';
import getFedasList from '~/composables/api/searchDiscover/getFedasList';
import updateFedas from '~/composables/api/searchDiscover/updateFedas';
import deleteFedas from '~/composables/api/searchDiscover/deleteFedas';
import type {
    Fedas,
    FedasListQueryParams, FedasPost, FedasQueryParams,
} from '~/composables/types/api/searchDiscover/fedas';

export default function(fedasId: string, fedasName: string) {
    const { $t } = useNuxtApp();
    const toasts = useToasts();

    const fedas: Ref<Fedas> = ref({ id: fedasId, name: fedasName });
    const fedasList: Ref<Fedas[]> = ref([]);

    const addOriginalFedas = (id: string, matrixFedas: string, selectedId: string) => {
        const originalFedasData = { id, name: `${matrixFedas} (Original)` };

        if (fedasList.value) {
            fedasList.value.sort((a, b) => parseInt(a.id, 10) - parseInt(b.id, 10));

            if (matrixFedas) {
                const selectedIndex = fedasList.value.findIndex(item => item.id === matrixFedas);

                if (selectedIndex !== -1) {
                    const selected = fedasList.value.splice(selectedIndex, 1)[0];
                    fedasList.value.unshift(selected);
                }
            }
        }

        const originalIndex = fedasList.value.findIndex(item => item.id === originalFedasData.id);
        fedasList.value.splice(originalIndex, 1);
        const selectedIndex = fedasList.value.findIndex(item => item.id === selectedId);

        if (selectedId === originalFedasData.id) {
            fedasList.value.unshift(originalFedasData);
        } else {
            if (selectedIndex !== -1) {
                const selected = fedasList.value.splice(selectedIndex, 1)[0];
                fedasList.value.unshift(selected);
            }

            if (originalIndex !== -1) {
                fedasList.value.splice(1, 0, originalFedasData);
            }
        }
    };

    const loadFedas = async(params: string): Promise<boolean> => {
        const fedasParam: FedasQueryParams = {
            idProductAbstract: params
        };

        const fedasData = await getFedas(fedasParam);

        if (fedasData.state === 'success') {
            await nextTick(() => {
                fedas.value = fedasData.fedas;
            });
        }

        return !fedasData.error;
    };

    const loadFedasList = async(params: FedasListQueryParams): Promise<boolean> => {
        const fedasData = await getFedasList(params);

        if (fedasData.state === 'success') {
            await nextTick(() => {
                fedasList.value = fedasData.fedasList;
            });
        }

        return !fedasData.error;
    };

    const fedasUpdate = async(params: FedasPost) : Promise<boolean> => {
        const updated = await updateFedas(params);

        if (updated.error) {
            toasts.add({
                type: 'error',
                headline: $t('Product.update_fedas_error_headline'),
                text: $t('Product.update_fedas_error_text')
            });
        }

        return !updated.error;
    };

    const fedasDelete = async(productId: number) : Promise<boolean> => {
        const deleted = await deleteFedas(productId);

        if (deleted.error) {
            toasts.add({
                type: 'error',
                headline: $t('Product.update_fedas_error_headline'),
                text: $t('Product.update_fedas_error_text')
            });
        }

        return !deleted.error;
    };

    return {
        loadFedas,
        loadFedasList,
        fedasUpdate,
        fedasDelete,
        addOriginalFedas,
        fedasList: computed(() => fedasList.value),
        fedas: computed(() => fedas.value),
    };
}
