import type {
    BranchCluster
} from '~/composables/types/api/searchDiscover/getBranchClusters';

export type BranchClusterState = {
    branchClusterTemplate: {
        resetData: boolean,
        initialClusterSetup: boolean,
        branchClusterId: number,
        branchClusterName: string,
        clusters: BranchCluster[]
    } | null,
    moduleBranchClusterTemplate: {
        resetData: boolean,
        initialClusterSetup: boolean,
        branchClusterId: number,
        branchClusterName: string,
        clusters: BranchCluster[]
    } | null,
    clusterActive: boolean
    clusterToggleActive: boolean
    branchClusterLoading: boolean | null
    moduleQuantitiesMatch: boolean,
}

type BranchClusterActions = {
    updateBranchClusterTemplate: (branchClusterTemplate: BranchClusterState['branchClusterTemplate']) => void,
    remove: () => void,
    getBranchClusterTemplate: (checkForModules?: boolean) => BranchClusterState['branchClusterTemplate'],
    setClusterState: (active: boolean) => void,
    setClusterLoadingState: (branchClusterLoading: boolean | null) => void
    getClusterLoadingState: () => boolean | null
    getClusterState: () => boolean,
    getClusterCount: (clusterName?: string) => number,
    updateModuleClusterTemplate: (moduleBranchClusterTemplate: BranchClusterState['moduleBranchClusterTemplate']) => void,
    updateBranchClusters: (clusters: BranchCluster[]) => void,
    updateModuleClusters: (moduleClusters: BranchCluster[]) => void,
    getModuleBranchClusterTemplate: () => BranchClusterState['moduleBranchClusterTemplate'],
    setModuleQuantitiesMatch: (matchValue: boolean) => void,
    getModuleQuantitiesMatch: () => boolean,
}

export const useBranchClusterStore = defineStore<string, BranchClusterState, any, BranchClusterActions>('branchCluster', {
    state: () => ({
        branchClusterTemplate: null,
        moduleBranchClusterTemplate: null,
        clusterActive: true,
        clusterToggleActive: false,
        branchClusterLoading: null,
        moduleQuantitiesMatch: true,
    }),
    actions: {
        updateBranchClusterTemplate(branchClusterTemplate) {
            if (branchClusterTemplate === null) {
                this.branchClusterTemplate = null;

                return;
            }

            if (typeof branchClusterTemplate?.resetData !== 'undefined') {
                this.branchClusterTemplate = {
                    ...branchClusterTemplate
                };
            }
        },
        updateModuleClusterTemplate(moduleBranchClusterTemplate) {
            this.moduleBranchClusterTemplate = moduleBranchClusterTemplate
        },
        updateModuleClusters(moduleClusters: BranchCluster[]) {
            if (this.moduleBranchClusterTemplate) {
                this.moduleBranchClusterTemplate.clusters = moduleClusters;
            }
        },
        updateBranchClusters(clusters: BranchCluster[]) {
            if (this.branchClusterTemplate) {
                this.branchClusterTemplate.clusters = [...clusters];
            }
        },
        remove() {
            this.branchClusterTemplate = null;
        },
        getBranchClusterTemplate(checkForModule: boolean = true) {
            if (checkForModule && this.moduleBranchClusterTemplate) {
                return this.moduleBranchClusterTemplate;
            }

            return this.branchClusterTemplate;
        },
        getModuleBranchClusterTemplate () {
            return this.moduleBranchClusterTemplate;
        },
        setClusterState(active) {
            this.clusterActive = active;
        },
        getClusterState() {
            return this.clusterActive;
        },
        setClusterLoadingState(currentState) {
            this.branchClusterLoading = currentState;
        },
        getClusterLoadingState() {
            return this.branchClusterLoading;
        },
        getClusterCount(clusterName?: string) {
            let count = 1;

            if (this.branchClusterTemplate && clusterName) {
                const clusterIndex = this.branchClusterTemplate.clusters.findIndex(cluster => cluster.name === clusterName);
                count = this.branchClusterTemplate.clusters?.[clusterIndex]?.branches?.length ?? 1;
            }

            return count;
        },
        setModuleQuantitiesMatch(matchValue: boolean) {
            this.moduleQuantitiesMatch = matchValue;
        },
        getModuleQuantitiesMatch() {
            return this.moduleQuantitiesMatch;
        },
    },
});
