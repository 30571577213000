import deleteNetPrices from '~/composables/api/cartConditions/deleteNetPrices';
import getNetPrices from '~/composables/api/cartConditions/getNetPrices';
import updateNetPrices from '~/composables/api/cartConditions/updateNetPrices';
import type {
    ArticleItem,
    DifferenceFlags,
    NetPrice,
    NetPricesFromConcretes,
    NetPricesQueryParams,
    NetPricesRequest,
    SmallestPrices,
    VariantItem,
} from '~/composables/types/api/cartConditions/netPrices';
import type { ConcreteProductBySizeAndColor } from '~/composables/types/api/searchDiscover/getMatrix';

const checkPriceExists = (prices: (VariantItem | ArticleItem)[]): boolean => prices.some(item => item.price > 0);

const hasFromText = (
    variantPrices: VariantItem[], articlePrices: ArticleItem [], matrixHasNetPrice: boolean, prices: NetPricesFromConcretes
): boolean => {
    const variantPricesArr = variantPrices?.map(item => item.price) ?? [];
    const articlePricesArr = articlePrices?.map(item => item.price) ?? [];
    const pricesArray = [...variantPricesArr, ...articlePricesArr];

    const uniquePrices = new Set(pricesArray.filter(price => price !== 0));
    const differences = matrixHasNetPrice ? uniquePrices.size > 1 : prices.differenceFlags.hasDifferentPurchasePrice;

    return differences ?? false;
};

const getVariantPriceByColor = (color: string, variantPrices: VariantItem[]): number => {
    const variant = variantPrices.find(item => item.color === color);

    return variant ? variant.price : 0;
};

const getArticlePrice = (gtin: string, articlePrices: ArticleItem[]): number => {
    const article = articlePrices.find(item => item.gtin === gtin);

    return article ? article.price : 0;
};

const getSmallestNetPriceFromArray = (prices: (VariantItem | ArticleItem)[]): number => {
    const positivePrices = prices.filter(item => item.price > 0);

    return Math.min(...positivePrices.map(item => item.price));
};

const getPricesInfoFromMatrixConcretes = (concretes: ConcreteProductBySizeAndColor): NetPricesFromConcretes => {
    const pricesToCheck = [
        'purchasePrice',
        'sellPrice',
        'purchasePriceOriginal',
        'sellPriceOriginal',
    ] as const;
    const smallestPrices: SmallestPrices = {
        purchasePrice: null,
        sellPrice: null,
        purchasePriceOriginal: null,
        sellPriceOriginal: null,
    };
    const flags: DifferenceFlags | any = {
        hasDifferentPurchasePrice: false,
        hasDifferentSellPrice: false,
    };
    let containsNetLabel: boolean = true;
    let containsKernLabel: boolean = false;
    let containsFKernLabel: boolean = true;
    const colors: string[] = [];
    const sizes: string[] = [];

    const getSmallestPrice = (price: number | undefined, smallestPrice: number | null): number => {
        if (typeof price === 'number' && (!smallestPrice || price < smallestPrice)) {
            return price;
        }

        return smallestPrice ? smallestPrice : 0;
    };

    const getDifferentPrice = (price: number | undefined, smallestPrice: number | null): boolean => (
        price !== smallestPrice && smallestPrice !== null
    );

    let index = 0;
    for (const size of Object.values(concretes)) {
        for (const product of Object.values(size)) {
            const { purchasePrice, sellPrice, purchasePriceOriginal, sellPriceOriginal, labels } = product;
            const prices = { purchasePrice, sellPrice, purchasePriceOriginal, sellPriceOriginal };

            pricesToCheck.forEach(priceKey => {
                smallestPrices[priceKey] = getSmallestPrice(prices[priceKey], smallestPrices[priceKey]);
                const hasDifferentKey: string = `hasDifferent${priceKey.charAt(0).toUpperCase() + priceKey.slice(1)}`;
                flags[hasDifferentKey] = getDifferentPrice(prices[priceKey], smallestPrices[priceKey]);
            });

            containsNetLabel = containsNetLabel ? labels?.includes('NETTO') : false;
            containsKernLabel = labels?.includes('KERN') || containsKernLabel;
            containsFKernLabel = containsFKernLabel ? labels?.includes('FK') : false;
            index === 0 ? colors.push(product.colorKey) : null;
        }
        index++;
    }

    for (const sizeKey of Object.keys(concretes)) {
        sizes.push(sizeKey);
    }

    const labels: string[] = [];
    containsKernLabel ? labels.push('KERN') : null;
    containsFKernLabel ? labels.push('FK') : null;

    return {
        smallestPrices: smallestPrices,
        differenceFlags: flags,
        containsNetLabel,
        labels,
        colors,
        sizes,
    };
};

export default function() {
    const netPrice: Ref<NetPrice[] | null> = ref(null);

    const loadNetPrices = async(params: NetPricesQueryParams): Promise<boolean> => {
        const netPricesData = await getNetPrices(params);

        if (netPricesData.state === 'success') {
            await nextTick(() => {
                netPrice.value = netPricesData.netPrices;
            });
        }

        return !netPricesData.error;
    };

    const netPricesUpdate = async(data: NetPricesRequest): Promise<boolean> => {
        const updatePrices = await updateNetPrices(data);

        return !updatePrices.error;
    };

    const netPricesDelete = async(params: NetPricesQueryParams): Promise<boolean> => {
        const deletePrices = await deleteNetPrices(params);

        return !deletePrices.error;
    };

    return {
        loadNetPrices,
        netPricesUpdate,
        netPricesDelete,
        getPricesInfoFromMatrixConcretes,
        checkPriceExists,
        hasFromText,
        getVariantPriceByColor,
        getSmallestNetPriceFromArray,
        getArticlePrice,
        netPrices: computed(() => netPrice.value),
    };
}
