import type { MatrixWarnings } from '~/composables/matrix/useMatrixWarnings';

export const useMinQuantityWarningsStore = defineStore('minQuantityWarnings', {
    state: () => ({
        minQuantityWarnings: {} as MatrixWarnings,
    }),

    actions: {
        set(warnings: MatrixWarnings) {
            this.minQuantityWarnings = warnings;
        },

        get(): MatrixWarnings {
            return this.minQuantityWarnings;
        }
    },
});
