import moment from 'moment';

const strToDate = (value: string, format:string = 'DD.MM.YYYY') => (
    moment(value, format).toDate()
);

const formatDate = (value: string, format: string) => {
    if (value && format) {
        return moment(String(value)).format(format);
    }

    return '';
};

const formatDateMdY = (value: string) => {
    if (!value) {
        return '';
    }

    return formatDate(value, 'MMM DD, YYYY');
};

const formatDateDmYWithDot = (value: string) => {
    if (!value) {
        return '';
    }

    return formatDate(value, 'DD.MM.YYYY');
};

const isDeadlineBeforeToday = (deadlineString: string) => {
    const deadline = moment(String(deadlineString));

    return moment().isBefore(deadline);
};

const todayDate = () => moment().format('MMM Do YY');

const calculateToday = (initialDate: string) => {
    if (!initialDate) {
        return '';
    }

    return moment(initialDate).diff(moment(), 'days');
};

const getMinDate = (dates: [string]) => {
    const moments = dates.map((date: string) => moment(date));

    return moment.min(moments);
};

export { strToDate, formatDate, formatDateMdY, formatDateDmYWithDot, isDeadlineBeforeToday, todayDate, calculateToday, getMinDate };
