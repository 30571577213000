import type { MatrixUpdateCartItem } from '~/composables/api/cartConditions/updateMatrix';
import updateMatrix from '~/composables/api/cartConditions/updateMatrix';
import clearMatrix from '~/composables/api/cartConditions/clearMatrix';
import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import useMatrixDateHelper from '~/composables/matrix/useMatrixDateHelper';
import type {
    BranchDeliveries,
    Branches,
    EnrichedMatrixDataSet,
    MatrixData
} from '~/composables/types/api/searchDiscover/getMatrix';
import type {
    MatrixModuleItem,
    MatrixOrderItem
} from '~/composables/types/api/cartConditions/matrix';
import type {
    BranchClusterState} from '~/composables/stores/useBranchClusterStore';
import {
    useBranchClusterStore
} from '~/composables/stores/useBranchClusterStore';
import type {
    BranchCluster,
    MappedPartnerIds
} from '~/composables/types/api/searchDiscover/getBranchClusters';
import useMatrixWarnings from '~/composables/matrix/useMatrixWarnings';
import useStore from '~/composables/service/useStore';

export type CartKey = {
    gtin: string,
    branchId: string,
    deliveryDate?: string,
    validFrom?: string,
    colorKey?: string,
}

export type MatrixStoredOrderItem = CartKey & {
    quantity?: number,
    minStock?: number,
    storedQuantity?: number,
    validTo?: string,
    orderDeadline?: string,
    partnerId?: string,
    colorId?:string,
}

export type NosKey = {
    gtin: string,
    branchId: string,
    validFrom?: string,
    validTo?: string,
    colorKey?: string,
}

export type MatrixNosItem = NosKey & {
    minStock?: number,
    standardStock?: number,
    storedQuantity?: number,
    validTo?: string,
    writable: boolean,
    releasedAt: string | null,
    partnerId: string,
    colorId: string,
}

type MatrixModuleItemToDelete = {
    branchId: string,
    partnerId: string,
    gtin: string,
    quantity: number,
    colorId: string,
}

export type MatrixModuleItemValidation = {
    items: MatrixModuleItemToDelete[],
    deliveryDate: string,
    increase: boolean,
}

type OrderItemsAfterUpdate = {
    [p: string]: number,
}

type DateItems = {
    fromDate: string,
    toDate: string,
    deadlineDate: string,
}

type ItemWithDeliveryDate = {
    deliveryDate?: string,
    gtin: string,
    branchId: string,
    quantity?: number,
}

const updateDelay = 1000;

const createCartKey = (values: CartKey): string => `${values.gtin}-${values.branchId}-${values.deliveryDate}`;
const createModuleKey = (values: Omit<CartKey, 'deliveryDate' & 'validFrom'>): string => `${values.gtin}-${values.branchId}`;
const createModuleValidationKey = (branchId: string, colorKey: string): string => `${branchId}-${colorKey}`;

const getUpdateItem = (item: MatrixStoredOrderItem, matrixData: MatrixData) => {
    const now = useMatrixDateHelper().createDateString();
    const deliveryDate = item.deliveryDate ?? '';
    const backupDeadlineDate = item.orderDeadline ??'';
    const backupDeliveryDate = item.deliveryDate ?? '';

    let found = true;
    let validDeliveryDates = matrixData.availableDeliveryDatesByGtin[item.gtin]?.find(
        (date: DateItems) => date.fromDate <= deliveryDate && date.toDate >= deliveryDate && date.deadlineDate >= now,
    );

    if (!validDeliveryDates) {
        found = false;
        validDeliveryDates = matrixData.availableDeliveryDatesByGtin[item.gtin]?.find(
            (date: DateItems) => date.deadlineDate >= now,
        );
    }

    const orderDeadlineDate = useStore().isVoStore() ? validDeliveryDates?.deadlineDate ?? backupDeadlineDate : useMatrixDateHelper().dateToString();

    return {
        gtin: item.gtin,
        branchId: item.branchId,
        partnerId: matrixData.branches.find((branch) => branch.label === item.branchId)?.partnerId,
        deliveryDate: found || item.quantity === 0 ? item.deliveryDate : validDeliveryDates?.fromDate ?? backupDeliveryDate,
        quantity: item.quantity,
        orderDeadline: orderDeadlineDate,
        colorId: item.colorId,
    };
};

// eslint-disable-next-line max-lines-per-function
export default function useMatrixOrderItems(errorHandler: (message: string) => void, cartUuid: Ref<string | null>) {
    const { $emitter, $t } = useNuxtApp();
    const cartsStore = useCartsStore();
    const {
        checkMinQuantities,
    } = useMatrixWarnings();
    const warningsStore = useMinQuantityWarningsStore();
    const {
        getBranchClusterTemplate,
        setClusterState,
        getClusterState,
        getModuleBranchClusterTemplate,
        updateModuleClusters,
        setModuleQuantitiesMatch,
    } = useBranchClusterStore();
    let matrixData: MatrixData;
    let orderItems: { [index: string]: MatrixStoredOrderItem } = {};
    let moduleItems: { [index: string]: MatrixModuleItem } = {};
    let timeout: number | undefined;
    let updatePromise: PromiseResponseData | null;
    let quantitiesUpdated = false;
    let reportMatrixClosedAfterRequest = false;
    let userFlowStateChanged = false;
    let netPricesChanged = false;
    let storedCartUuid = cartsStore.activeCart?.id;
    const quantitiesSubmitted: Ref<boolean> = ref(true);
    const minQuantitiesAchieved = computed(() => Object.keys(warningsStore.get()).length <= 0);

    const needsUpdate = () => Object.values(orderItems).some(item => item.storedQuantity !== item.quantity);

    const getColorIdByGtin = (gtin: string): string => matrixData.concreteProductsByGtin?.[gtin]?.colorKey || '-';

    const updateUserflowToggleState = (changed: boolean) => {
        userFlowStateChanged = changed;
    };

    const updateNetPricesChangedState = (changed: boolean) => {
        netPricesChanged = changed;
    };

    const getCartQuantitiesByBranches = () => {
        const result: BranchDeliveries = {};
        for (const item of Object.values(orderItems)) {
            result[item.branchId] ??= [];
            result[item.branchId].push(item);
        }

        return result;
    };

    const matrixClosed = () => {
        if (timeout || updatePromise) {
            reportMatrixClosedAfterRequest = true;

            return;
        }
        if (!userFlowStateChanged && !quantitiesUpdated && !netPricesChanged) {
            reportMatrixClosedAfterRequest = true;

            return;
        }
        if (matrixData) {
            const detail = {
                cartId: storedCartUuid,
                catalogId: matrixData!.catalogId,
                brandCode: matrixData!.brandCode,
                modelCode: matrixData!.modelNumberFrontend,
                sapModelCode: matrixData!.modelNumber,
                quantitiesUpdated: quantitiesUpdated || userFlowStateChanged || netPricesChanged,
            };

            const event = new CustomEvent('matrixClosed', { detail });
            window.dispatchEvent(event);
        }
    };

    const backendUpdatedHandler = (response: any) => {
        updatePromise = null;
        if (response.error) {
            errorHandler(response);
        } else {
            quantitiesUpdated = true;

            $emitter.$emit('update:updatingQuantities', true);
            if (reportMatrixClosedAfterRequest) {
                matrixClosed();
            }
        }
    };

    const stopTimeout = () => {
        if (timeout !== undefined) {
            clearTimeout(timeout);
            timeout = undefined;

            window.removeEventListener('beforeunload', onBeforeUnload);
        }
    };

    const declusterBranchItems = (items: MatrixUpdateCartItem[]): MatrixUpdateCartItem[] => {
        const branchClusterStore = getBranchClusterTemplate();
        if (!matrixData.items.branchCluster || !branchClusterStore) {
            return items;
        }

        const activeCluster = branchClusterStore.clusters;

        const declusteredItems: MatrixUpdateCartItem[] = [];

        items.map(item => {
            const branchCluster = activeCluster.find(cluster => item.branchId === cluster.name);

            if (!branchCluster?.branches) {
                declusteredItems.push(item);

                return;
            }

            Object.entries(branchCluster.partnerIds).forEach(([branch, partnerId]) => {
                declusteredItems.push({
                    ...item,
                    branchId: branch,
                    partnerId: partnerId,
                });
            });
        });

        return declusteredItems;
    };

    const updateMatrixOrderItems = (): PromiseResponseData | null => {
        stopTimeout();
        if (matrixData === null) {
            return null;
        }
        let items: MatrixUpdateCartItem[] = [];
        try {
            for (const [key] of Object.entries(orderItems)) {
                if (orderItems[key].quantity === ('storedQuantity' in orderItems[key] ? orderItems[key].storedQuantity : orderItems[key].quantity)) {
                    continue;
                }

                const updateItem: MatrixUpdateCartItem = getUpdateItem(orderItems[key], matrixData);
                items.push(updateItem);
                orderItems[key].storedQuantity = orderItems[key].quantity;

                if (orderItems[key].quantity === 0) {
                    delete orderItems[key];
                }
            }
        } catch (e: any) {
            errorHandler(e);

            return null;
        }

        items = declusterBranchItems(items);
        updatePromise = updateMatrix(matrixData, items, storedCartUuid);
        updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
            errorHandler(err);
            $emitter.$emit('update:updatingQuantities', false);
        });

        return updatePromise;
    };

    const compareGtinQuantities = <MDataType extends ItemWithDeliveryDate>(
        cluster: BranchCluster,
        itemsForDate: MDataType[]
    ): boolean => {
        const gtinQuantityInCluster = cluster.branches.map(branchId => itemsForDate.filter(item => item.branchId === branchId)
            .reduce((acc, currentItem) => {
                acc[currentItem.gtin] = (acc[currentItem.gtin] || 0) + (currentItem.quantity || 0);

                return acc;
            }, {} as Record<string, number>));

        let quantitiesValid = true;
        gtinQuantityInCluster.forEach((current, index) => {
            gtinQuantityInCluster.forEach((compare, compareIndex) => {
                if (index !== compareIndex) {
                    Object.keys(current).forEach(gtin => {
                        if (current[gtin] !== compare[gtin]) {
                            quantitiesValid = false;
                        }
                    });
                }
            });
        });

        return quantitiesValid;
    };

    const updateMissingModuleBranches = (
        moduleBranchClusters: BranchCluster[],
        branchClusters: BranchCluster[]
    ): BranchCluster[] => {
        return moduleBranchClusters.map(moduleCluster => {
            const correspondingBranchCluster = branchClusters.find(
                branchCluster => branchCluster.name === moduleCluster.name
            );

            if (!correspondingBranchCluster) {
                return {
                    ...moduleCluster,
                    missingModuleBranches: moduleCluster.branches,
                    missingModuleBranchNames: moduleCluster.branchNames,
                    missingModulePartnerIds: moduleCluster.partnerIds,
                };
            }

            const missingModuleBranches = correspondingBranchCluster.branches.filter(
                branch => !moduleCluster.branches.includes(branch)
            );

            const missingModuleBranchNames = correspondingBranchCluster.branchNames.filter(
                (_, index) => !moduleCluster.branches.includes(correspondingBranchCluster.branches[index])
            );

            const missingModulePartnerIds = missingModuleBranches.reduce((result, branch: string) => {
                if (branch in correspondingBranchCluster.partnerIds) {
                    result[Number(branch)] = correspondingBranchCluster.partnerIds[Number(branch)];
                }
                return result;
            }, {} as MappedPartnerIds);

            return {
                ...moduleCluster,
                missingModuleBranches,
                missingModuleBranchNames,
                missingModulePartnerIds,
            };
        });
    };

    const addMissingModuleBranchesToCluster = (
        moduleBranchClusters: BranchCluster[]
    ): BranchCluster[] => {
        const lastClusterIndex = moduleBranchClusters.length - 1;
        const lastCluster = moduleBranchClusters[lastClusterIndex];

        moduleBranchClusters.forEach((cluster, index) => {
            if (index !== lastClusterIndex) {
                lastCluster.branches.push(...cluster.missingModuleBranches.filter(branch => !lastCluster.branches.includes(branch)));
                lastCluster.branchNames.push(...cluster.missingModuleBranchNames.filter(name => !lastCluster.branchNames.includes(name)));

                Object.entries(cluster.missingModulePartnerIds).forEach(([branchId, partnerId]) => {
                    if (!lastCluster.partnerIds[Number(branchId)]) {
                        lastCluster.partnerIds[Number(branchId)] = partnerId;
                    }
                });
            }
        });

        return moduleBranchClusters;
    }

    const findNonLastBranchCluster = (branchCluster: BranchCluster[], branchId: string) =>
        branchCluster.find((cluster, index) => index !== branchCluster.length - 1 && cluster.branches.includes(branchId));

    const addBranchToModuleCluster = (moduleCluster: BranchCluster, tempBranchCluster: BranchCluster, branchId: string) => {
        if (!moduleCluster.branches.includes(branchId)) {
            moduleCluster.branches.push(branchId);

            const branchIndex = tempBranchCluster.branches.indexOf(branchId);
            const branchName = tempBranchCluster.branchNames[branchIndex];
            moduleCluster.branchNames.push(branchName);

            const partnerId = tempBranchCluster.partnerIds[Number(branchId)];
            if (partnerId) {
                moduleCluster.partnerIds[Number(branchId)] = partnerId;
            }
        }
    };

    const addBranchToAdditionalCluster = (moduleClusters: BranchCluster[], branches: Branches[], branchId: string) => {
        const branch = branches.find(branch => branch.label === branchId);
        if (!branch) {
            return;
        }

        const lastCluster = moduleClusters[moduleClusters.length - 1];
        if (!lastCluster.additionalModuleBranches?.includes(branchId)) {
            lastCluster.additionalModuleBranches = [...(lastCluster.additionalModuleBranches ?? []), branch.label];
            lastCluster.additionalModuleBranchNames = [...(lastCluster.additionalModuleBranchNames ?? []), branch.name];
            lastCluster.additionalModulePartnerIds = {
                ...(lastCluster.additionalModulePartnerIds ?? {}),
                [Number(branchId)]: branch.partnerId
            };

            removeExistingBranchEntries(lastCluster, branchId, branch.name);
        }
    };

    const removeExistingBranchEntries = (cluster: BranchCluster, branchId: string, branchName: string) => {
        const branchIndex = cluster.branches.indexOf(branchId);
        if (branchIndex > -1) {
            cluster.branches.splice(branchIndex, 1);
        }

        const branchNameIndex = cluster.branchNames.indexOf(branchName);
        if (branchNameIndex > -1) {
            cluster.branchNames.splice(branchNameIndex, 1);
        }

        delete cluster.partnerIds[Number(branchId)];
    };

    const populateModuleClusters = (
        moduleItems: MatrixModuleItem[],
        branchCluster: BranchCluster[],
        moduleClusters: BranchCluster[],
        branches: Branches[]
    ): BranchCluster[] => {
        moduleItems.forEach(item => {
            const branchId = item.branchId;

            const tempBranchCluster = findNonLastBranchCluster(branchCluster, branchId);
            const moduleCluster = moduleClusters.find(cluster => cluster.name === tempBranchCluster?.name);

            if (tempBranchCluster && moduleCluster) {
                addBranchToModuleCluster(moduleCluster, tempBranchCluster, branchId);
            } else if (!tempBranchCluster) {
                addBranchToAdditionalCluster(moduleClusters, branches, branchId);
            }
        });

        return moduleClusters;
    };

    const getAllGtinsForAllBranchesAndCompare = <MDataType extends ItemWithDeliveryDate>(
        cluster: BranchCluster,
        itemsForDate: MDataType[]
    ): boolean => {
        const gtinsForCheckedCluster = cluster.branches.map(branchId => itemsForDate.filter(item => item.branchId === branchId));

        let gtinsValid = true;

        gtinsForCheckedCluster.map((array, index) => {
            gtinsForCheckedCluster.map((arrayToCompare, compareIndex) => {
                if (index !== compareIndex) {
                    array.map(item => {
                        if (!arrayToCompare.some(itemToCompare => itemToCompare.gtin === item.gtin)) {
                            gtinsValid = false;
                        }
                    });
                }
            });
        });

        return gtinsValid;
    };

    const mapBranchCluster = <MDataType extends ItemWithDeliveryDate>(
        items: MDataType[],
        branchClusters: BranchCluster[],
        moduleBranchClusters: BranchCluster[] | null,
    ): MDataType[] | false => {
        const deliveryDates = Array.from(new Set(items.map(item => item.deliveryDate)));
        const clusteredItems: MDataType[] = [];
        const activeCluster = moduleBranchClusters ? moduleBranchClusters : branchClusters;

        const isError = deliveryDates.some(date => {
            const itemsForDate = items.filter(item => item.deliveryDate === date && item?.quantity !== 0);

            return activeCluster.some(cluster => {
                if (!getAllGtinsForAllBranchesAndCompare(cluster, itemsForDate)) {
                    return true;
                }

                if (!getAllGtinsForAllBranchesAndCompare(cluster, itemsForDate) ||
                    !compareGtinQuantities(cluster, itemsForDate)) {
                    return true;
                }

                const representativeItem = itemsForDate.filter(item =>
                    cluster.branches.some(branch => branch === item.branchId)
                );


                if (representativeItem) {
                    representativeItem.some(item => {
                        clusteredItems.push({
                            ...item,
                            branchId: cluster.name
                        });
                    });
                }

                return false;
            });
        });

        if (isError) {
            setModuleQuantitiesMatch(false);

            return false;
        }

        return clusteredItems.length > 0 ? clusteredItems : items;
    };

    const processModuleBranchClusters = (
        items: MatrixModuleItem[],
        branchCluster: BranchCluster[],
        moduleBranchClusters: BranchCluster[],
        branches: Branches[]
    ) => {
        let clusters = populateModuleClusters(items, branchCluster, moduleBranchClusters, branches);
        clusters = updateMissingModuleBranches(clusters, branchCluster);
        return addMissingModuleBranchesToCluster(clusters);
    };

    const addAdditionalClusterIfNeeded = (moduleBranchClusters: BranchCluster[]) => {
        const lastCluster = moduleBranchClusters?.[moduleBranchClusters.length - 1];

        if (lastCluster?.additionalModuleBranches?.length) {
            const additionalCluster = createAdditionalCluster(lastCluster);
            moduleBranchClusters.splice(moduleBranchClusters.length - 1, 0, additionalCluster);
        }
    };

    const createAdditionalCluster = (lastCluster: BranchCluster): BranchCluster => ({
        name: $t('Matrix.further_branches_with_modules'),
        branches: lastCluster.additionalModuleBranches ?? [],
        branchNames: lastCluster.additionalModuleBranchNames ?? [],
        partnerIds: lastCluster.additionalModulePartnerIds ?? {},
        isDefault: false,
        missingModuleBranches: [],
        missingModuleBranchNames: [],
        missingModulePartnerIds: {},
        additionalModuleBranches: [],
        additionalModuleBranchNames: [],
        additionalModulePartnerIds: {}
    });

    const populateMatrixDataBranches = (moduleBranchClusters: BranchCluster[]) => {
        if (!matrixData) {
            return;
        }

        const onlyBranchClusters: Branches[] = moduleBranchClusters.map(cluster => ({
            id: Number(cluster.branches[0]),
            label: cluster.name,
            name: cluster.name,
            partnerId: cluster.partnerIds[Number(cluster.branches[0])]
        }));

        matrixData.branches = onlyBranchClusters;
    };

    const populateAndCleanClusters = (moduleBranchClusters: BranchCluster[]): BranchCluster[] => {
        moduleBranchClusters.forEach(cluster => {
            if (cluster.branches.length === 0) {
                cluster.branches = [...cluster.missingModuleBranches];
                cluster.branchNames = [...cluster.missingModuleBranchNames];
                cluster.partnerIds = { ...cluster.missingModulePartnerIds };

                cluster.missingModuleBranches = [];
                cluster.missingModuleBranchNames = [];
                cluster.missingModulePartnerIds = {};
            }
        });

        const allBranches: string[] = [];
        const allBranchNames: string[] = [];
        const allPartnerIds: string[] = [];

        for (let i = 0; i < moduleBranchClusters.length - 1; i++) {
            const cluster = moduleBranchClusters[i];
            allBranches.push(...cluster.branches);
            allBranchNames.push(...cluster.branchNames);
            allPartnerIds.push(...Object.keys(cluster.partnerIds));
        }

        const arrayIncludes = (array: string[], item: string): boolean => array.includes(item);

        const lastCluster = moduleBranchClusters[moduleBranchClusters.length - 1];
        if (lastCluster.isDefault) {
            lastCluster.branches = lastCluster.branches.filter(branch => !arrayIncludes(allBranches, branch));
            lastCluster.branchNames = lastCluster.branchNames.filter(branchName => !arrayIncludes(allBranchNames, branchName));

            const filteredPartnerIds: Record<string, string> = {};
            Object.keys(lastCluster.partnerIds).forEach(partnerId => {
                if (!arrayIncludes(allPartnerIds, partnerId)) {
                    filteredPartnerIds[partnerId] = lastCluster.partnerIds[Number(partnerId)];
                }
            });
            lastCluster.partnerIds = filteredPartnerIds;
        }

        return moduleBranchClusters;
    };

    const prepareModuleClusters = (items: MatrixModuleItem[], branches: Branches[]) => {
        const branchCluster = getBranchClusterTemplate(false)?.clusters;
        let moduleBranchClusters = getModuleBranchClusterTemplate()?.clusters ?? null;

        if (!moduleBranchClusters || !branchCluster) {
            return;
        }

        moduleBranchClusters = processModuleBranchClusters(items, branchCluster, moduleBranchClusters, branches);
        addAdditionalClusterIfNeeded(moduleBranchClusters);
        moduleBranchClusters = populateAndCleanClusters(moduleBranchClusters);
        updateModuleClusters(moduleBranchClusters);
        populateMatrixDataBranches(moduleBranchClusters);
    };

    const validateBranchesInSelectedBranchCluster = <VDataType extends ItemWithDeliveryDate>(
        items: VDataType[],
    ): VDataType[] => {
        if (!getClusterState()) {
            return items;
        }

        const branchCluster = getBranchClusterTemplate(false)?.clusters;
        const moduleBranchClusters = getModuleBranchClusterTemplate()?.clusters ?? null;

        if (!branchCluster || !items.length) {
            setClusterState(true);

            return items;
        }

        const clusteredQuantities = mapBranchCluster<VDataType>(items, branchCluster, moduleBranchClusters);

        if (clusteredQuantities) {
            setClusterState(true);

            return clusteredQuantities;
        }
        setClusterState(false);

        return items;
    };

    const updateCartData = (response: MatrixData, forceUpdate: boolean = false) => {
        // Force immediate update if update from old matrix still pending
        if (timeout !== undefined || forceUpdate) {
            stopTimeout();
            updateMatrixOrderItems();
        }

        orderItems = {};
        moduleItems = {};
        matrixData = response;

        const filterAndMapItems = (items: MatrixStoredOrderItem[], targetContainer: any, modules = false) => items
            .filter(item => response.concreteProductsByGtin?.[item.gtin])
            .forEach(item => {
                const concreteProduct = response?.concreteProductsByGtin?.[item?.gtin];
                item.colorKey = (item?.gtin && concreteProduct) ? concreteProduct.colorKey : '-';
                if (!item?.colorId) {
                    item.colorId = (item?.gtin && concreteProduct) ? concreteProduct.colorKey.split(' - ')[0] : ''
                }
                if (!item.partnerId) {
                    item.partnerId = response.branches.find((branch) => branch.label === item.branchId)?.partnerId;
                }
                item.storedQuantity = item.quantity ?? 0;
                targetContainer[modules ? createModuleKey(item) : createCartKey(item)] = item;
            });

        let items = matrixData.items.orderItems;
        let modules = matrixData.items.moduleItems;

        prepareModuleClusters(modules, matrixData.originalBranches);

        items = validateBranchesInSelectedBranchCluster<MatrixOrderItem>(items);
        modules = validateBranchesInSelectedBranchCluster<MatrixModuleItem>(modules);

        filterAndMapItems(items, orderItems);
        filterAndMapItems(modules, moduleItems, true);
    };

    const onBeforeUnload = () => {
        updateMatrixOrderItems();
        stopTimeout();
    };

    const updateMatrixDataOrderItems = () => {
        if (!matrixData) {
            return;
        }

        // @ts-ignore
        matrixData.items.orderItems = declusterBranchItems(
            Object.values(orderItems).filter(item => item.quantity !== 0)
        );
    };

    const updateMatrixOrderItemsDelayed = (): Promise<any> | null => {
        stopTimeout();

        if (needsUpdate()) {
            window.addEventListener('beforeunload', onBeforeUnload);

            return new Promise(resolve => {
                storedCartUuid = cartUuid.value ?? cartsStore.activeCart?.id;
                if (minQuantitiesAchieved.value) {
                    timeout = window.setTimeout(() => {
                        quantitiesSubmitted.value = false;
                        updateMatrixOrderItems()?.then(resolve);
                    }, updateDelay);
                }
                updateMatrixDataOrderItems();
            });
        }

        return null;
    };

    const updateMatrixOrderItemsBranchCluster = async(branchClusterTemplate: BranchClusterState['branchClusterTemplate'] | null) => {
        const branchClusterId = branchClusterTemplate?.branchClusterId;
        storedCartUuid = cartUuid.value ?? cartsStore.activeCart?.id;

        if (!branchClusterId) {
            matrixData.items.branchCluster = '-1';

            updatePromise = updateMatrix(matrixData, [], storedCartUuid);
            updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
                errorHandler(err);
                $emitter.$emit('update:updatingQuantities', false);
            });

            return;
        }

        if (matrixData.items.branchCluster !== branchClusterId.toString()) {
            await nextTick(() => {
                matrixData.items.branchCluster = branchClusterId.toString();
            });

            updatePromise = updateMatrix(matrixData, [], storedCartUuid);
            updatePromise!.then(backendUpdatedHandler).catch((err: any) => {
                errorHandler(err);
                $emitter.$emit('update:updatingQuantities', false);
            });
            setClusterState(true);
        }
    };

    const updateMatrixOrderItemQuantity = (
        params: MatrixStoredOrderItem,
        matrixResponseData?: EnrichedMatrixDataSet,
        groupByValue?: boolean
    ) => {
        const key = createCartKey(params);
        const current = orderItems[key];
        const newItem = { ...params };
        if (current) {
            current.storedQuantity = current.quantity;
            current.quantity = newItem.quantity;
        } else if (newItem.quantity !== 0) {
            newItem.storedQuantity = 0;
            orderItems[key] = newItem;
        }

        if (matrixResponseData) {
            matrixData.items.orderItems = Object.keys(orderItems).map(itemKey => orderItems[itemKey] as MatrixOrderItem);
            const result = getCartQuantitiesByBranches();
            if (!checkMinQuantities(matrixResponseData, result, groupByValue ?? false)) {
                return Promise<null>;
            }
        }

        return updateMatrixOrderItemsDelayed();
    };

    const resetMatrixOrderItemQuantities = (
        date?: string,
        matrixResponseData?: EnrichedMatrixDataSet,
        groupByValue?: boolean
    ): PromiseResponseData | null => {
        if (matrixData === null) {
            return null;
        }

        !date ? orderItems = {} : null;
        if (!date) {
            storedCartUuid = cartUuid.value ?? cartsStore.activeCart?.id;
            updatePromise = clearMatrix(matrixData, storedCartUuid);
            updatePromise!.then(backendUpdatedHandler).catch((err: any) => errorHandler(err));
            matrixData.items.orderItems = [];
            orderItems = {};
        } else {
            Object.values(orderItems).filter((orderItem => (orderItem.deliveryDate === date)
            )).map((orderItem) => {
                updatePromise = updateMatrixOrderItemQuantity({
                    ...orderItem,
                    quantity: 0
                }, matrixResponseData, groupByValue) as Promise<any>;
            });
        }

        return updatePromise;
    };

    const getCartQuantity = (deliveryDate: string, branchId: string, partnerId: string, gtin: string): number => {
        const key = createCartKey({ gtin: gtin, branchId: branchId, deliveryDate: deliveryDate });

        return orderItems[key]?.quantity ?? 0;
    };

    const getCartModuleQuantity = (activeDateIndex: number, branchId: string, partnerId: string, gtin: string): number | null => {
        if (activeDateIndex !== 0) {
            return null;
        }

        const key = createModuleKey({ gtin: gtin, branchId: branchId });

        return moduleItems[key]?.quantity ?? null;
    };

    const getModuleItemsMinQuantities = (gtin: string, branchId: string): number => Object.values(moduleItems).reduce((totalQuantity, item) => {
        const quantity = item?.quantity || 0;
        const colorKeyToValidate = getColorIdByGtin(gtin);
        const colorKeyModuleItem = getColorIdByGtin(item.gtin);

        if (colorKeyToValidate === colorKeyModuleItem && branchId === item.branchId) {
            return totalQuantity + quantity;
        }

        return totalQuantity;
    }, 0);

    const getCartQuantitiesForDate = (date: string): MatrixStoredOrderItem[] => Object.values(orderItems).filter(
        item => item.deliveryDate === date
    );

    const validateModuleItemQuantityByBranchAndColor = (orderItemsAfterUpdate: OrderItemsAfterUpdate): boolean => {
        let validQuantity = true;

        for (const { branchId, gtin } of Object.values(orderItems)) {
            const deleteKey = createModuleValidationKey(branchId, getColorIdByGtin(gtin));
            const minQuantity = getModuleItemsMinQuantities(gtin, branchId);

            if (orderItemsAfterUpdate[deleteKey] < minQuantity) {
                validQuantity = false;
                break;
            }
        }

        return validQuantity;
    };

    const validateModuleItemQuantity = (orderItemsToDelete: MatrixModuleItemValidation, moduleTypeArticle: boolean): boolean => {
        const isModuleItems = Boolean(Object.values(moduleItems).length);

        if (!isModuleItems) {
            return true;
        }

        const orderItemsAfterUpdate: OrderItemsAfterUpdate = {};

        if (orderItemsToDelete.items.length === 0) {
            const itemsOnDate = getCartQuantitiesForDate(orderItemsToDelete.deliveryDate);
            orderItemsToDelete.items = itemsOnDate.map(item => ({
                gtin: item.gtin,
                branchId: item.branchId,
                partnerId: item.partnerId ?? '',
                quantity: 0,
                colorKey: getColorIdByGtin(item.gtin),
                colorId: item.colorId ?? '',
            }));
        }

        for (const { gtin, branchId, quantity } of Object.values(orderItems)) {
            const updateKey = createModuleValidationKey(branchId, getColorIdByGtin(gtin));
            orderItemsAfterUpdate[updateKey] = (orderItemsAfterUpdate[updateKey] || 0) + (quantity || 0);
        }

        for (const { gtin, branchId, quantity } of orderItemsToDelete.items) {
            const key = createCartKey({ gtin, branchId, deliveryDate: orderItemsToDelete.deliveryDate });
            const currentQuantity = orderItems[key]?.quantity || 0;
            const deleteKey = createModuleValidationKey(branchId, getColorIdByGtin(gtin));

            orderItemsAfterUpdate[deleteKey] = (orderItemsAfterUpdate[deleteKey] || 0) + (currentQuantity * -1) + (quantity || 0);
        }
        let validArticleQuantities: boolean = true;

        if (moduleTypeArticle) {
            validArticleQuantities = orderItemsToDelete.items.every(item => {
                const moduleItem = Object.values(moduleItems).find(module => item.gtin === module.gtin && item.branchId === module.branchId);

                return !moduleItem || item.quantity >= moduleItem.quantity;
            });
        }

        return validateModuleItemQuantityByBranchAndColor(orderItemsAfterUpdate) && validArticleQuantities;
    };

    const calculateAdjustedQuantity = (quantity: number | undefined, gtin: string) => {
        if (quantity === undefined) {
            return undefined;
        }

        const packagingUnit = matrixData?.concreteProductsByGtin?.[gtin]?.packagingUnit ?? 1;
        const mod = quantity % packagingUnit;

        let adjustedQuantity = quantity;

        if (mod !== 0) {
            adjustedQuantity += packagingUnit - mod;
        }

        adjustedQuantity = Math.max(0, adjustedQuantity);

        return adjustedQuantity;
    };

    return {
        updateUserflowToggleState,
        updateNetPricesChangedState,
        updateMatrixOrderItemsBranchCluster,
        getModuleItemsMinQuantities,
        calculateAdjustedQuantity,
        updateMatrixOrderItems,
        updateCartData,
        matrixClosed,
        getCartQuantity,
        getCartQuantitiesForDate,
        getCartModuleQuantity,
        validateModuleItemQuantity,
        getCartQuantitiesByBranches,
        updateMatrixOrderItemQuantity,
        resetMatrixOrderItemQuantities,
        declusterBranchItems,
        quantitiesSubmitted
    };
}
