<template>
    <div ref="tooltipContainer" class="tooltip-container" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <div v-if="text.length" class="scales">{{ text }}</div>
        <Teleport to="body">
            <div v-if="tooltipVisible" ref="tooltipElement" class="tooltip" :style="tooltipStyle">
                <table class="product-scales__table">
                    <thead>
                    <tr class="product-scales__table__row">
                        <th>{{ $t('Product.color_number') }}</th>
                        <template v-for="(head, index) in getScales()" :key="index">
                            <th>{{ $t('Product.from') }} {{ head }}</th>
                        </template>
                    </tr>
                    </thead>
                    <tbody>
                    <tr class="product-scales__table__row">
                        <td>{{ data.label }}</td>
                        <template v-for="(from, i) in getPrices()" :key="i">
                            <td>{{ useMoneyFormat(Number(from)) }}</td>
                        </template>
                    </tr>
                    </tbody>
                </table>

            </div>
        </Teleport>
    </div>
</template>

<script lang="ts" setup>
import type { MatrixHeaderSection } from '~/composables/types/matrix/useMatrixContentDataTypes';
import useMoneyFormat from '~/composables/utils/useMoneyFormat';

const props = defineProps<{
    text: string,
    data: any,
}>();

const tooltipContainer = ref<HTMLElement | null>(null);
const tooltipElement = ref<HTMLElement | null>(null);
const tooltipVisible = ref(false);
const tooltipStyle = reactive({ top: '0px', left: '0px' });
const marginBottom = 10;

const showTooltip = () => {
    tooltipVisible.value = true;

    if (tooltipContainer.value && tooltipElement.value) {
        const containerRect = tooltipContainer.value.getBoundingClientRect();
        const tooltipRect = tooltipElement.value.getBoundingClientRect();
        tooltipStyle.left = `${containerRect.left}px`;
        tooltipStyle.top = `${containerRect.top - tooltipRect.height + window.scrollY - marginBottom}px`;
    }
};

const hideTooltip = () => {
    tooltipVisible.value = false;
};

const getScales = () => {
    const section: MatrixHeaderSection = props.data;

    const all_cells = section.columns.map(column => column.cells).flat().filter(cell => !cell.blocked);
    if (all_cells[0].scalePrices) {
        return Object.keys(all_cells[0].scalePrices);
    }

    return [];
};

const getPrices = () => {
    const section: MatrixHeaderSection = props.data;

    const all_cells = section.columns.map(column => column.cells).flat().filter(cell => !cell.blocked);
    if (all_cells[0].scalePrices) {
        return Object.values(all_cells[0].scalePrices);
    }

    return [];
};

onMounted(() => {
    if (tooltipContainer.value) {
        tooltipContainer.value.addEventListener('mouseenter', showTooltip);
        tooltipContainer.value.addEventListener('mouseleave', hideTooltip);
    }
});

onBeforeUnmount(() => {
    if (tooltipContainer.value) {
        tooltipContainer.value.removeEventListener('mouseenter', showTooltip);
        tooltipContainer.value.removeEventListener('mouseleave', hideTooltip);
    }
});
</script>

<style lang="scss" scoped>
.scales {
    @include helper-color(white);
    @include helper-border-radius(rem(18));
    @include helper-font-size(smaller);
    @include helper-font-line-height(5);
    @include helper-font-weight(medium);
    @include helper-color-bg(label-ms);

    padding: sp(xxs) sp(xs2);
    margin: 0 sp(xs);
    cursor: default;
    max-width: fit-content;
}

.product-scales {
    &__table {
        border-collapse: collapse;
        width: 100%;

        th,
        td {
            padding: sp(xs) sp(s);
            white-space: nowrap;
            text-align: left;

            @include helper-font-size(small);
            @include helper-font-weight(regular);
        }

        &__row {
            @include helper-color(text-title);

            &:first-child th {
                @include helper-color(state-default);
                @include helper-color-bg(light-gray);
                @include helper-font-weight(medium);
            }

            &:nth-child(odd) td {
                @include helper-color-bg(white);
            }
        }
    }
}

.tooltip-container {
    position: relative;
    display: inline-block;
    cursor: default;
}

.tooltip {
    position: absolute;
    top: 0;
    left: 0;
    padding: sp(xxs);
    margin-bottom: sp(xxs);
    z-index: 1600;

    @include helper-border($setting-color-gray-2, 1px, solid);
    @include helper-color-bg(white);
    @include helper-border-radius(rem(10));

    &::before {
        content: '';
        border-color: $setting-color-gray-2 transparent transparent $setting-color-gray-2;
        position: absolute;
        bottom: rem(-5.6);
        left: rem(30);
        width: rem(10);
        height: rem(10);
        transform: translateX(-50%) rotate(-135deg);

        @include helper-border($setting-color-gray-2, 1px, solid);
        @include helper-color-bg(white);
    }
}
</style>
