const scrollThreshold = 100;
const overscroll = 50;
const scrollAmount = 4;
const columnsParentClass = 'matrix-columns';
const totalsRow = 'matrix__column-totals';
const clickIdentifier = {
    delay: 200,
    click: true
};
const scrollBoosterContentClass = 'is-scrollbooster-content';

 
export default function useSelectionRect() {
    let startX = -1;
    let startY = -1;
    let endX = -1;
    let endY = -1;
    let isPointerDown = false;

    const container = ref<HTMLElement | null>(null);
    const rectangle = ref<HTMLElement | null>(null);

    let startCallback: ((xStart: number, yStart: number, event: PointerEvent) => boolean) | undefined;
    let updateCallback: ((rect: DOMRect, xStart: number, yStart: number, move: boolean) => void) | undefined;
    let finishedCallback: ((rect: DOMRect, xStart: number, yStart: number) => void) | undefined;

    const registerCallbacks = (
        start?: (xStart: number, yStart: number, event: PointerEvent) => boolean,
        update?: (rect: DOMRect, xStart: number, yStart: number, move: boolean) => void,
        finished?: (rect: DOMRect, xStart: number, yStart: number) => void,
    ) => {
        startCallback = start;
        updateCallback = update;
        finishedCallback = finished;
    };

    const clearSelection = () => {
        startX = -1;
        endX = -1;
        startY = -1;
        endY = -1;
        isPointerDown = false;
        if (rectangle.value !== null) {
            rectangle.value.style.width = '0';
            rectangle.value.style.height = '0';
        }

         
        window.removeEventListener('pointermove', onPointerMove, { capture: true });
         
        window.removeEventListener('pointerup', onPointerUp, { capture: true });
         
        window.removeEventListener('keyup', keyUp, { capture: true });
    };

     
    const getScrollableParent = (isX: boolean, forward: boolean): HTMLElement | undefined => {
        let parent: HTMLElement | null = container.value?.parentElement ?? null;
        let xParent: HTMLElement | null = container.value ?? null;
        if (isX) {
            while (xParent !== null) {
                if (xParent.scrollWidth > xParent.clientWidth) {
                    if (xParent.tagName === 'BODY') {
                        return document.documentElement;
                    }
                    if (
                        (forward && xParent.scrollLeft + xParent.clientWidth < xParent.scrollWidth) ||
                        (!forward && (xParent.scrollLeft !== 0 || xParent.classList.contains(columnsParentClass)))
                    ) {
                        return xParent;
                    }
                }
                xParent = xParent.parentElement;
            }
        } else {
            while (parent !== null) {
                if (parent.scrollHeight > parent.clientHeight) {
                    if (parent.tagName === 'BODY') {
                        return document.documentElement;
                    }
                    if ((forward && parent.scrollTop + parent.clientHeight < parent.scrollHeight) || (!forward && parent.scrollTop !== 0)) {
                        return parent;
                    }
                }
                parent = parent.parentElement;
            }
        }

        return undefined;
    };

    const getMustScrollXElement = (element: HTMLElement) => {
        if (element.classList.contains(scrollBoosterContentClass)) {
            return element.parentElement!.querySelector('.matrix-columns-scrollbar') as HTMLElement;
        }

        return element;
    };

    const scrollIfOnYEdge = (event: PointerEvent, containerRect: DOMRect) => {
        const yDownScrollable = getScrollableParent(false, true);
        if (yDownScrollable) {
            if (yDownScrollable === document.documentElement) {
                if (containerRect.bottom > window.innerHeight - overscroll) {
                    event.clientY > window.innerHeight - scrollThreshold ? yDownScrollable.scrollBy(0, scrollAmount) : {};
                }
            } else {
                const yRect = yDownScrollable.getBoundingClientRect();
                if (yRect.bottom < containerRect.bottom + overscroll) {
                    if (yDownScrollable.offsetTop + yDownScrollable.clientHeight - event.clientY < scrollThreshold) {
                        yDownScrollable.scrollBy(0, scrollAmount);
                    }
                }
            }
        }

        const yUpScrollable = getScrollableParent(false, false);
        if (yUpScrollable) {
            if (yUpScrollable === document.documentElement) {
                if (containerRect.top < overscroll) {
                    event.clientY < scrollThreshold ? yUpScrollable.scrollBy(0, -scrollAmount) : {};
                }
            } else if (containerRect.y < scrollThreshold) {
                event.clientY - yUpScrollable.offsetTop < scrollThreshold ? yUpScrollable.scrollBy(0, -scrollAmount) : {};
            }
        }
    };

    const scrollIfOnXEdge = (event: PointerEvent, containerRect: DOMRect) => {
        const xRightScrollable = getScrollableParent(true, true);
        if (xRightScrollable) {
            const xRightMustScrollElement = getMustScrollXElement(xRightScrollable);

            if (xRightScrollable === document.documentElement) {
                if (containerRect.right > window.innerWidth - overscroll) {
                    event.clientX > window.innerWidth - scrollThreshold ? xRightMustScrollElement.scrollBy(scrollAmount, 0) : {};
                }
            } else {
                const xRect = xRightScrollable.getBoundingClientRect();
                if (xRect.right < containerRect.right + overscroll) {
                    if (xRightScrollable.offsetLeft + xRightScrollable.clientWidth - event.clientX < scrollThreshold) {
                        xRightMustScrollElement.scrollBy(scrollAmount, 0);
                    }
                }
            }
        }

        const xLeftScrollable = getScrollableParent(true, false);
        if (xLeftScrollable) {
            const xLeftMustScrollElement = getMustScrollXElement(xLeftScrollable);

            if (xLeftScrollable === document.documentElement) {
                if (containerRect.left < overscroll) {
                    event.clientX < scrollThreshold ? xLeftMustScrollElement.scrollBy(-scrollAmount, 0) : {};
                }
            } else if (event.clientX < xLeftScrollable.offsetLeft + scrollThreshold) {
                xLeftMustScrollElement.scrollBy(-scrollAmount, 0);
            }
        }
    };

    const scrollIfOnEdge = (event: PointerEvent) => {
        if (container.value === null) {
            return;
        }

        const containerRect = container.value.getBoundingClientRect();
        scrollIfOnYEdge(event, containerRect);
        scrollIfOnXEdge(event, containerRect);
    };

    const clickScrollDistinguish = (event: PointerEvent) => {
        const timeout = setTimeout(() => {
            clickIdentifier.click = event.type === 'pointerup';
        }, clickIdentifier.delay);

        if (!clickIdentifier.click && event.type !== 'pointerup') {
            scrollIfOnEdge(event);
            window.clearTimeout(timeout);
        }
    };

     
    const updateSelectionRect = (event: PointerEvent, finished = false, move: boolean = false) => {
        if (rectangle.value === null || container.value === null) {
            return;
        }

        clickScrollDistinguish(event);

        const x = Math.max(0, Math.min(startX, endX));
        const y = Math.max(0, Math.min(startY, endY));
        const width = Math.min(container.value.scrollWidth - x, Math.max(startX, endX) - x);
        const height = Math.min(container.value.scrollHeight - y, Math.max(startY, endY) - y);
        const rect = new DOMRect(x, y, width, height);

        rectangle.value.style.left = `${x}px`;
        rectangle.value.style.top = `${y}px`;
        rectangle.value.style.width = `${width}px`;
        rectangle.value.style.height = `${height}px`;

        if (finished) {
            if (finishedCallback) {
                finishedCallback(rect, startX, startY);
            }
        } else if (updateCallback) {
            updateCallback(rect, startX, startY, move);
        }
    };

    const keyUp = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            clearSelection();
        }
    };

    const onPointerDown = (event: PointerEvent) => {
        if (container.value === null ||
            rectangle.value === null ||
            (event.target as HTMLElement).classList.contains(columnsParentClass) ||
            (event.target as HTMLElement).classList.contains(totalsRow)) {
            return;
        }
        if (isPointerDown) {
            event.preventDefault();
            event.stopPropagation();
            updateSelectionRect(event, true, false);
            clearSelection();

            return;
        }

        const containerRect = container.value.getBoundingClientRect();
        startX = event.clientX - containerRect.left + container.value.scrollLeft;
        startY = event.clientY - containerRect.y;
        endX = startX;
        endY = startY;

        if (startCallback && !startCallback(startX, startY, event)) {
            return;
        }

        isPointerDown = true;

        event.preventDefault();
        event.stopPropagation();

         
        window.addEventListener('pointermove', onPointerMove, { capture: true });
         
        window.addEventListener('pointerup', onPointerUp, { capture: true });
         
        window.addEventListener('keyup', keyUp, { capture: true });
    };

    const onPointerMove = (event: PointerEvent) => {
        if (container.value === null) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();

        const containerRect = container.value.getBoundingClientRect();
        endX = event.clientX - containerRect.left + container.value.scrollLeft;
        endY = event.clientY - containerRect.y;
        updateSelectionRect(event, false, true);
    };

    const onPointerUp = (event: PointerEvent) => {
        updateSelectionRect(event, true);
        clearSelection();
    };

    onBeforeUnmount(() => {
        container.value?.removeEventListener('pointerdown', onPointerDown);
    });

    watch(container, (value, old) => {
        old ? old.removeEventListener('pointerdown', onPointerDown) : {};
        value ? value.addEventListener('pointerdown', onPointerDown) : {};
        clearSelection();
    });

    return {
        container,
        rectangle,
        registerCallbacks,
    };
}
