import type {
    MatrixCartItemsResponse,
} from '~/composables/types/api/cartConditions/matrix';
import useParamsToQuery from '~/composables/api/useParamsToQuery';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { MatrixData } from '~/composables/types/api/searchDiscover/getMatrix';
import type {
    ApiGenericErrorResponse,
    PromiseResponseData
} from '~/composables/types/api/apiResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getMatrixCartItems(matrixData: MatrixData, cartUuid: string | null)
    : PromiseResponseData<MatrixCartItemsResponse> {
    const { fetchCcCartApi } = fetchOrderDetermination();

    if (!cartUuid) {
        const cartsStore = useCartsStore();

        if (!cartsStore.activeCart) {
            return handleError({ error: 'No active cart' });
        }

        cartUuid = cartsStore.activeCart!.id;
    }

    const params = {
        cartId: cartUuid,
        catalogId: matrixData.catalogId,
        brandCode: matrixData.brandCode,
        modelCode: matrixData.modelNumberFrontend,
    };

    const url = `/models/items?${useParamsToQuery(params)}`;

    try {
        const response = <any> await fetchCcCartApi(url);

        if (response?.error) {
            return handleError(response);
        }

        return handleSuccess<MatrixCartItemsResponse>(response);
    } catch (error: any) {
        return handleError(<ApiGenericErrorResponse>error);
    }
}
