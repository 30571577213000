import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import useParamsToQuery from '~/composables/api/useParamsToQuery';
import type {
    NetPricesQueryParams,
    NetPricesResponse,
} from '~/composables/types/api/cartConditions/netPrices';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getNetPrices(params: NetPricesQueryParams)
    : PromiseResponseData<NetPricesResponse> {
    const url: string = `/netto-price?${useParamsToQuery(params)}`;
    const { fetchCcCartApi } = fetchOrderDetermination();

    try {
        const response = await fetchCcCartApi(url) as any;

        if (response.error) {
            return handleError(response);
        }

        return handleSuccess<NetPricesResponse>({ netPrices: response });
    } catch (error: any) {
        return handleError(error);
    }
}
