<template>
    <div :class="{ 'suppliers': true, 'suppliers-multiple': multiSuppliers}">
        <div :class="{'suppliers__label': true, 'suppliers__label-single': !multiSuppliers}">
            <span>{{$t('General.supplier')}}</span>
        </div>
        <div class="suppliers__selection">
            <div v-for="(supplier, index) in suppliers" :key="index" class="suppliers__selection-row">
                <AtomRadioButton
                    v-if="suppliers.length > 1"
                    class="radio-button__cart-flyout"
                    :name="supplier.text"
                    size="xs"
                    :selected="index === selectedSupplierIndex"
                    type="radio"
                    @click="selectSupplier(index)"
                />
                <span class="suppliers__selection-name" :title="supplier.text">{{supplier.text}}</span>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import type { MatrixSupplier } from '~/composables/types/api/searchDiscover/getMatrix';

const props = defineProps<{
    supplierIndex: number,
    suppliers: MatrixSupplier[],
}>();

const emit = defineEmits<{
    (e: 'update:supplierIndex', index: number): void,
}>();

const selectedSupplierIndex = ref(props.supplierIndex);
const multiSuppliers = computed(() => props.suppliers.length > 1);

const selectSupplier = (index: number) => {
    if (selectedSupplierIndex.value !== index) {
        selectedSupplierIndex.value = index;
        emit('update:supplierIndex', index);
    }
};
</script>
<style scoped lang="scss">
.suppliers {
    height: rem(70);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: sp(s);

    @include helper-color(text-title);
    @include helper-font-size(small);
    @include helper-font-line-height(5);

    &__label {
        width: rem(60);
        height: rem(40);
        display: flex;
        align-items: flex-start;

        @include helper-font-weight(bold);
    }

    &__label-single {
        height: rem(56);
        align-items: center;
    }

    &__selection {
        width: rem(260);
        height: rem(56);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: sp(xxs);

        @include helper-font-weight(regular);

        &-row {
            display: flex;
            align-items: center;
            gap: rem(6);
        }

        &-name {
            max-width: rem(200);

            @include helper-ellipse-dots();
        }
    }
}
</style>
