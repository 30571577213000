import type {
    MatrixNosItem,
    MatrixNosItemsResponse,
} from '~/composables/types/api/cartConditions/matrix';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { MatrixData } from '~/composables/types/api/searchDiscover/getMatrix';
import useParamsToQuery from '~/composables/api/useParamsToQuery';
import { fetchOrderDetermination } from '../apiFactories/fetchOrderDetermination';
import type {
    PromiseResponseData,
} from '~/composables/types/api/apiResponse';

const findWritableItem = (items: MatrixNosItem[]): MatrixNosItem | undefined => items.find(item => item.writable);

const pickNewestReleaseDate = (nosItems: MatrixNosItem[]): MatrixNosItem | null => {
    const filteredResponse = nosItems.filter(item => item.releasedAt !== null);

    filteredResponse.sort((a, b) => {
        const dateA = new Date(a.releasedAt);
        const dateB = new Date(b.releasedAt);

        return dateB.getTime() - dateA.getTime();
    });

    return filteredResponse.length > 0 ? filteredResponse[0] : null;
};

const assignDeliveryDateToWwsQuantities = (nosItems: MatrixNosItem[]): MatrixNosItem[] => {
    const writableDate = findWritableItem(nosItems);

    if (!writableDate) {
        const lastReleased = pickNewestReleaseDate(nosItems);

        if (!lastReleased) {
            return nosItems;
        }

        return nosItems.map(item => ({
            ...item,
            validFrom: lastReleased.validFrom,
            validTo: lastReleased.validTo,
            supplierId: lastReleased.supplierId,
        }));
    }

    return nosItems.map(item => ({
        ...item,
        validFrom: writableDate.validFrom,
        validTo: writableDate.validTo,
        supplierId: writableDate.supplierId,
        minStock: item.minStock === -1 ? 0 : item.minStock,
        standardStock: item.standardStock === -1 ? 0 : item.standardStock,
    }));
};

export default async function getMatrixNosItems(matrixData: MatrixData)
    : PromiseResponseData<MatrixNosItemsResponse> {
    const cartsStore = useCartsStore();
    const { fetchCcNosApi } = fetchOrderDetermination();

    if (!cartsStore.activeCart) {
        return handleError('No active cart');
    }

    const params = {
        catalogId: matrixData.catalogId,
        brandCode: matrixData.brandCode,
        modelCode: matrixData.modelNumberFrontend
    };

    const url = `/nos?${useParamsToQuery(params)}`;

    try {
        const response = await fetchCcNosApi(url) as any;

        if (response.error) {
            return handleError(response);
        }

        return handleSuccess<MatrixNosItemsResponse>({
            nosItems: assignDeliveryDateToWwsQuantities(response)
        });
    } catch (error: any) {
        return handleError(error);
    }
}
