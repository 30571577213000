<template>
    <div v-if="!loadingFailed" class="net-prices">
        <div v-if="!loading" class="net-prices__matrix-head">
            <div class="net-prices__ek-prices">
                <AtomProductPrices
                    :product="matrixHasNetPrice ? netPurchasePrice : purchasePrice"
                    view-type="netPrice"
                    :has-net-price="matrixHasNetPrice || prices.containsNetLabel"
                    :has-from-suffix="showFromText"
                />
            </div>

            <div v-if="!supplierIsIntersport && !hasNetLabel" class="net-prices__button">
                <AtomButton
                    v-role:not="RoleTypes.SUPPLIER"
                    class="net-prices-edit"
                    type="secondary"
                    icon="edit"
                    size="m"
                    :title="$t('Matrix.net_prices_button_title')"
                    @click="openNetPricesModal"
                />
                <div
                    v-if="matrixHasNetPrice"
                    class="net-prices__button-check-icon"
                >
                    <AtomButton
                        class="net-prices__fa-check"
                        type="tertiary"
                        size="s"
                        :circular="true"
                        icon="check"
                    />
                </div>
            </div>

            <div class="net-prices__upe-prices">
                <AtomProductPrices
                    :product="sellPrice"
                    view-type="netPrice"
                    :has-from-suffix="prices.differenceFlags.hasDifferentSellPrice"
                />
            </div>
        </div>

        <div v-else>
            <AtomSpinner class="net-prices__spinner"/>
        </div>

        <AtomModal
            class="net-prices__modal"
            :open="!!modalOpen"
            :buttons="netPricesModalButtons"
            :show-x-button="false"
            @button-clicked="buttonClicked"
            @update:open="buttonClicked"
        >
            <div class="net-prices__modal-header">
                <span class="net-prices__modal-title">{{$t('Matrix.store_net_prices')}}</span>
                <AtomTabs
                    v-model='activeTabIndex'
                    :class="{ 'net-prices__tabs': true, 'net-prices__tabs-variant-disabled': colorsDisabled }"
                    :items='tabItems'
                    :line-style='true'
                    @click="tabChanged"
                />
                <div class="net-prices__tabs-info-wrapper">
                    <div class="net-prices__tabs-info">
                        <AtomIcon name="info" class="net-prices__tabs-icon"/>
                        <span>{{$t('Matrix.net_prices_info_text')}}</span>
                    </div>
                    <AtomNotification
                        v-if="showNotification"
                        class="net-prices__tabs-warning"
                        type="warning"
                        :text="$t('Matrix.net_prices_warning')"
                    />
                </div>
            </div>
            <div v-if="activeTabIndex === modelTabIndex" class="net-prices__tabs-model">
                <div class="net-prices__tabs-model-input-field">
                    <div class="net-prices__tabs-model-input-field-elem">
                        <span>{{ modelName }}</span>
                        <AtomNumberInput
                            :class="{'net-prices__number-input': true}"
                            :input-type="'text'"
                            :model-value="modelPrice"
                            :allow-zero="true"
                            :precision="precision"
                            :side-buttons="false"
                            :currency-symbol="true"
                            :min="0"
                            :allow-undefined="false"
                            size="s"
                            @update:model-value="updateModelPriceInput"
                        />
                    </div>
                    <div class="net-prices__tabs-line"/>
                </div>
                <div class="net-prices__tabs-delete-prices">
                    <AtomButton type='tertiary' icon="trash-3" size='m' :text="$t('Matrix.net_prices_delete')" @click="removePrices"/>
                </div>
            </div>
            <div v-else-if="activeTabIndex === variantsTabIndex" class="net-prices__tabs-variant">
                <div
                    v-for="(color, key) in prices.colors"
                    :key="key"
                    class="net-prices__tabs-variant-input-field"
                >
                    <div class="net-prices__tabs-variant-input-field-elem">
                        <span>{{ color }}</span>
                        <AtomNumberInput
                            :class="{'net-prices__number-input': true}"
                            :input-type="'text'"
                            :model-value="getVariantPriceByColor(getColorKey(color) ?? '', variantPrices as VariantItem[])"
                            :allow-zero="true"
                            :precision="precision"
                            :side-buttons="false"
                            :currency-symbol="true"
                            :min="0"
                            :allow-undefined="false"
                            size="s"
                            @update:model-value="updateVariantPricesInput($event, getColorKey(color) ?? '')"
                        />
                    </div>
                    <div class="net-prices__tabs-line"/>
                </div>
                <div class="net-prices__tabs-delete-prices">
                    <AtomButton type='tertiary' icon="trash-3" size='m' :text="$t('Matrix.net_prices_delete')" @click="removePrices"/>
                </div>
            </div>
            <div v-else-if="activeTabIndex === articlesTabIndex" class="net-prices__tabs-article-wrapper">
                <div class="net-prices__tabs-article">
                    <div class="net-prices__table-container">
                        <div class="net-prices__table-header">
                            <div class="net-prices__table-color-header">
                                {{ $t('General.color') }}
                            </div>
                            <div class="net-prices__table-size-headers">
                                <div v-for="size in prices.sizes" :key="size" class="net-prices__table-size-header" :title="size">
                                    {{ size }}
                                </div>
                            </div>
                        </div>
                        <div class="net-prices__table-body">
                            <div v-for="(color, colorKey) in prices.colors" :key="colorKey" class="net-prices__table-color">
                                <div class="net-prices__table-color-label" :title="color">{{ color }}</div>
                                <div
                                    v-for="(value, key) in Object.values(concretes)"
                                    :key="key"
                                    :class="{
                                        'net-prices__table-values': true,
                                        'net-prices__lock-bg': checkAllSectionsBlocked(key, colorKey)
                                    }"
                                >
                                    <AtomIcon
                                        v-if="checkAllSectionsBlocked(key, colorKey)"
                                        class="net-prices__lock-icon"
                                        name="lock-2"
                                    />
                                    <div v-else>
                                        <AtomNumberInput
                                            :class="{'net-prices__table-number-input': true}"
                                            :input-type="'text'"
                                            :model-value="getArticlePrice(getCell(key, colorKey)?.gtin ?? '', articlesPrice)"
                                            :allow-zero="true"
                                            :precision="precision"
                                            :side-buttons="false"
                                            :currency-symbol="true"
                                            :min="0"
                                            :allow-undefined="false"
                                            size="s"
                                            @update:model-value="updateArticlePricesInput($event, getCell(key, colorKey)?.gtin ?? '')"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeTabIndex === articlesTabIndex" class="net-prices__tabs-delete-prices net-prices__tabs-article-delete">
                <AtomButton type='tertiary' icon="trash-3" size='m' :text="$t('Matrix.net_prices_delete')" @click="removePrices"/>
            </div>
        </AtomModal>
    </div>
</template>

<script lang="ts" setup>
import type {
    ArticleItem,
    NetPricesFromConcretes,
    NetPricesQueryParams,
    NetPricesRequest,
    NetProductPrices,
    VariantItem,
} from '~/composables/types/api/cartConditions/netPrices';
import type { ModalButton } from '~/composables/types/ui';
import type { ConcreteProductBySizeAndColor } from '~/composables/types/api/searchDiscover/getMatrix';
import type { MatrixHeaderSection } from '~/composables/types/matrix/useMatrixContentDataTypes';
import { ProductTypes } from '~/composables/types/productTypes';
import { RoleTypes } from '~/composables/types/roleTypes';

const props = defineProps<{
    brandId: string,
    modelCode: string,
    catalogId: string,
    modelName: string,
    supplierIsIntersport: boolean,
    concretes: ConcreteProductBySizeAndColor,
    sections: MatrixHeaderSection[] | null,
}>();

const emit = defineEmits<{
    (e: 'update:netPricesChanged', changed: boolean): void,
}>();

const {
    loadNetPrices,
    netPricesUpdate,
    netPricesDelete,
    getPricesInfoFromMatrixConcretes,
    checkPriceExists,
    hasFromText,
    getVariantPriceByColor,
    getArticlePrice,
    getSmallestNetPriceFromArray,
    netPrices
} = useNetPrices();

const { $t, $hasRole } = useNuxtApp();
const toasts = useToasts();

const modalOpen: Ref<boolean> = ref(false);
const loading: Ref<boolean> = ref(false);
const loadingFailed: Ref<boolean> = ref(false);
const colorsDisabled: Ref<boolean> = ref(false);

const queryParams: NetPricesQueryParams = {
    brandId: props.brandId,
    modelCode: props.modelCode,
    catalogId: props.catalogId,
};

const precision = 2;
const modelTabIndex = 0;
const variantsTabIndex = 1;
const articlesTabIndex = 2;
const initialTabIndex: Ref<number> = ref(0);

const tabItems = ref([
    { text: $t('Matrix.net_prices_modal_tab_model') },
    { text: $t('Matrix.net_prices_modal_tab_variant') },
    { text: $t('Matrix.net_prices_modal_tab_article') },
]);
const activeTabIndex: Ref<number> = ref(0);

const netPricesModalButtons: ModalButton[] = [
    {
        event: 'close',
        text: $t('General.cancel'),
        type: 'secondary',
    },
    {
        event: 'save',
        text: $t('Matrix.net_prices_save'),
        type: 'primary',
    }
];

const pricesChanged :Ref<boolean> = ref(false);
const editSuccessful :Ref<boolean> = ref(false);
const matrixHasNetPrice :Ref<boolean> = ref(false);
const showFromText :Ref<boolean> = ref(false);

const prices: NetPricesFromConcretes = getPricesInfoFromMatrixConcretes(props.concretes);
const hasNetLabel: boolean = prices.containsNetLabel;

const originalModelPrice: Ref<number> = ref(0);
const modelPrice: Ref<number> = ref(0);

const originalVariantPrices: Ref<VariantItem[]> = ref([]);
const variantPrices: Ref<VariantItem[]> = ref([]);

const originalArticlePrices: Ref<ArticleItem[]> = ref([]);
const articlePrices: Ref<ArticleItem[]> = ref([]);
const articlesPrice = computed(() => articlePrices.value as ArticleItem[]);
const firstSection = computed(() => props.sections?.[0]);
const intersportKernFkPriceType = 11;

const purchasePriceIntersportType = Object.values(props.concretes).some(
    productSizes => Object.values(productSizes).some(
        productColor => productColor.purchasePriceIntersportType === intersportKernFkPriceType
    )
) ?
    intersportKernFkPriceType :
    undefined;

const purchasePrice: NetProductPrices = {
    purchasePrice: prices.smallestPrices.purchasePrice,
    purchasePriceOriginal: prices.smallestPrices.purchasePriceOriginal,
    labels: prices.labels,
    purchasePriceIntersportType
};

const netPurchasePrice: Ref<NetProductPrices> = ref({
    purchasePrice: prices.smallestPrices.purchasePrice,
    purchasePriceOriginal: prices.smallestPrices.purchasePriceOriginal,
    labels: prices.labels,
});

const sellPrice: NetProductPrices = {
    sellPrice: prices.smallestPrices.sellPrice,
    sellPriceOriginal: prices.smallestPrices.sellPriceOriginal,
};

const showNotification = computed(() => {
    switch (activeTabIndex.value) {
    case modelTabIndex:
        return checkPriceExists(variantPrices.value) || checkPriceExists(articlePrices.value);
    case variantsTabIndex:
        return checkPriceExists(articlePrices.value) || modelPrice.value > 0;
    case articlesTabIndex:
        return modelPrice.value > 0 || checkPriceExists(variantPrices.value);
    default:
        return false;
    }
});

const checkAllSectionsBlocked = (key: number, colorKey: number): boolean => {
    if (!props.sections) {
        return false;
    }

    return props.sections.every(section => {
        const column = section.columns[key];
        if (!column) {
            return false;
        }

        return column.cells[colorKey]?.blocked;
    });
};

const getCell = (key: number, colorKey: number) => firstSection.value?.columns[key]?.cells[colorKey];

const resetVariantPrices = () => {
    variantPrices.value = [];
};

const resetArticlePrices = () => {
    articlePrices.value = [];
};

const openNetPricesModal = () => {
    originalModelPrice.value = modelPrice.value;
    originalVariantPrices.value = variantPrices.value.map(item => ({ color: item.color, price: item.price }));
    originalArticlePrices.value = articlePrices.value.map(item => ({ price: item.price, gtin: item.gtin }));
    pricesChanged.value = false;
    editSuccessful.value = false;
    modalOpen.value = true;
};

const removePrices = () => {
    modelPrice.value = 0;
    resetVariantPrices();
    resetArticlePrices();
};

const closeModal = (buttonIndex: number) => {
    modalOpen.value = false;

    if (buttonIndex === 0) {
        return;
    }

    modelPrice.value < 0 ? modelPrice.value = 0 : null;
    variantPrices.value = variantPrices.value.map(item => ({ ...item, price: Math.max(0, item.price) }));
    articlePrices.value = articlePrices.value.map(item => ({ ...item, price: Math.max(0, item.price) }));

    if (activeTabIndex.value === modelTabIndex && originalModelPrice.value !== modelPrice.value) {
        pricesChanged.value = buttonIndex > 0;
        matrixHasNetPrice.value = pricesChanged.value && modelPrice.value > 0;

        return;
    }

    if (activeTabIndex.value === variantsTabIndex && !useDeepCompare(originalVariantPrices.value, variantPrices.value)) {
        pricesChanged.value = buttonIndex > 0;

        matrixHasNetPrice.value = pricesChanged.value && variantPrices.value.some(item => item.price > 0);

        return;
    }

    if (activeTabIndex.value === articlesTabIndex && !useDeepCompare(originalArticlePrices.value, articlePrices.value)) {
        pricesChanged.value = buttonIndex > 0;

        matrixHasNetPrice.value = pricesChanged.value && articlePrices.value.some(item => item.price > 0);
    }
};

const tabChanged = (index: number) => {
    if (index === activeTabIndex.value) {
        return;
    }
    activeTabIndex.value = index;
};

const getNewModelPrice = (): NetPricesRequest => [{
    modelCode: props.modelCode,
    brandId: props.brandId,
    catalogId: props.catalogId,
    nettoPrice: parseFloat(modelPrice.value.toString()),
    type: ProductTypes.MODEL,
}];

const getColorKey = (color: string): string => {
    const split = color.split(' - ');

    return split[0];
};

const getNewVariantPrices = (): NetPricesRequest => {
    const netVariantPrices: NetPricesRequest = [];

    for (const variant of Object.values(variantPrices.value)) {
        if (variant.price > 0) {
            netVariantPrices.push({
                modelCode: props.modelCode,
                brandId: props.brandId,
                catalogId: props.catalogId,
                colorId: getColorKey(variant.color),
                nettoPrice: variant.price,
                type: ProductTypes.VARIANT,
            });
        }
    }

    return netVariantPrices;
};

const getNewArticlePrices = (): NetPricesRequest => {
    const netArticlePrices: NetPricesRequest = [];

    for (const article of Object.values(articlePrices.value)) {
        if (article.price > 0) {
            netArticlePrices.push({
                modelCode: props.modelCode,
                brandId: props.brandId,
                catalogId: props.catalogId,
                gtin: article.gtin,
                nettoPrice: article.price,
                type: ProductTypes.ARTICLE,
            });
        }
    }

    return netArticlePrices;
};

const updateModelPriceInput = (value: number | string | undefined): number => {
    modelPrice.value = parseFloat(value ? value?.toString() : '0');

    return modelPrice.value;
};

const updateVariantPricesInput = (value: number | string | undefined, color: string): number => {
    const index = variantPrices.value.findIndex(item => item.color === color);

    if (index !== -1) {
        variantPrices.value[index].price = parseFloat(value ? value.toString() : '0');

        return variantPrices.value[index].price;
    }
    variantPrices.value.push({ color, price: value ? Number(value) : 0 });

    return value ? Number(value) : 0;
};

const updateArticlePricesInput = (value: number | string | undefined, gtin: string): number => {
    const index = articlePrices.value.findIndex(item => item.gtin === gtin);

    if (index !== -1) {
        articlePrices.value[index].price = parseFloat(value ? value.toString() : '0');

        return articlePrices.value[index].price;
    }
    articlePrices.value.push({ gtin, price: value ? Number(value) : 0 });

    return value ? Number(value) : 0;
};

const updateNetPurchasePrice = (price: number) => {
    netPurchasePrice.value = {
        purchasePriceOriginal: purchasePrice.purchasePriceOriginal ?? 0,
        purchasePrice: price,
        labels: matrixHasNetPrice.value ? [] : prices.labels,
    };
};

const updateModelPrice = () => {
    modelPrice.value = modelPrice.value !== originalModelPrice.value ? modelPrice.value : netPrices.value?.[0]?.nettoPrice ?? 0;
    updateNetPurchasePrice(modelPrice.value);
};

const updatePrices = () => {
    const variantUpdate = activeTabIndex.value === variantsTabIndex;
    const priceChanged = variantUpdate
        ? !useDeepCompare(originalVariantPrices.value, variantPrices.value)
        : !useDeepCompare(originalArticlePrices.value, articlePrices.value);

    const netPricesArray = netPrices?.value ?? [];
    const smallestNetPrice = netPricesArray.length > 0 ? Math.min(...netPricesArray.map(item => item.nettoPrice)) : 0;
    const priceArray = variantUpdate ? variantPrices.value : articlePrices.value;
    const smallestPrice = getSmallestNetPriceFromArray(priceArray);

    const price = priceChanged ? smallestPrice : smallestNetPrice;
    updateNetPurchasePrice(price);
};

const updateNetPrices = () => {
    switch (activeTabIndex.value) {
    case modelTabIndex:
        updateModelPrice();
        break;
    case variantsTabIndex:
    case articlesTabIndex:
        updatePrices();
        break;
    default:
        break;
    }
};

const initializeModelPrice = () => {
    activeTabIndex.value = modelTabIndex;
    modelPrice.value = netPrices.value?.[0].nettoPrice ?? 0;
    updateNetPurchasePrice(modelPrice.value);
};

const initializePrices = (index: number) => {
    activeTabIndex.value = index;
    const pricesArr = [];

    if (netPrices.value) {
        for (const item of netPrices.value) {
            if (activeTabIndex.value === variantsTabIndex) {
                pricesArr.push({
                    color: getColorKey(item.colorId ?? ''),
                    price: item.nettoPrice,
                });
            } else {
                pricesArr.push({
                    gtin: item.gtin ?? '',
                    price: item.nettoPrice,
                });
            }
        }
    }

    activeTabIndex.value === variantsTabIndex ?
        variantPrices.value = pricesArr as VariantItem[] :
        articlePrices.value = pricesArr as ArticleItem[];

    updateNetPurchasePrice(getSmallestNetPriceFromArray(pricesArr));
};

const setNetPrices = () => {
    if (!netPrices.value || !netPrices.value.length) {
        removePrices();

        return;
    }

    switch (netPrices.value?.[0]?.type) {
    case ProductTypes.MODEL:
        initializeModelPrice();
        matrixHasNetPrice.value = modelPrice.value > 0;
        break;
    case ProductTypes.VARIANT:
        initializePrices(variantsTabIndex);
        matrixHasNetPrice.value = checkPriceExists(variantPrices.value);
        break;
    case ProductTypes.ARTICLE:
        initializePrices(articlesTabIndex);
        matrixHasNetPrice.value = checkPriceExists(articlePrices.value);
        break;
    default:
        break;
    }

    colorsDisabled.value = !prices.colors.some((color: string) => {
        const split = color.split(' - ');

        return split[0].length;
    });

    initialTabIndex.value = activeTabIndex.value;
};

const savePrices = (): Promise<boolean> => {
    let data: NetPricesRequest = [];

    switch (activeTabIndex.value) {
    case modelTabIndex:
        data = getNewModelPrice();
        resetVariantPrices();
        resetArticlePrices();
        break;
    case variantsTabIndex:
        data = getNewVariantPrices();
        modelPrice.value = 0;
        resetArticlePrices();
        break;
    case articlesTabIndex:
        data = getNewArticlePrices();
        modelPrice.value = 0;
        resetVariantPrices();
        break;
    default:
        break;
    }

    return netPricesUpdate(data);
};

const getNetPrices = async() => {
    if (props.supplierIsIntersport) {
        return;
    }

    loading.value = true;
    const loadedPrices = await loadNetPrices(queryParams);
    loading.value = false;

    if (!loadedPrices) {
        loadingFailed.value = true;
    }

    setNetPrices();
    showFromText.value = hasFromText(variantPrices.value, articlePrices.value, matrixHasNetPrice.value, prices);
};

const buttonClicked = async(index: number | boolean) => {
    closeModal((typeof index === 'number') ? index : 0);

    if (!index || !pricesChanged.value) {
        modelPrice.value = originalModelPrice.value;
        variantPrices.value = originalVariantPrices.value.map(item => ({ color: item.color, price: item.price }));
        articlePrices.value = originalArticlePrices.value.map(item => ({ gtin: item.gtin, price: item.price }));

        return;
    }

    if (!matrixHasNetPrice.value) {
        removePrices();
        netPurchasePrice.value = {
            purchasePriceOriginal: purchasePrice.purchasePriceOriginal ?? 0,
            purchasePrice: purchasePrice.purchasePrice ?? 0,
            labels: matrixHasNetPrice.value ? [] : purchasePrice.labels,
        };
    }

    editSuccessful.value = matrixHasNetPrice.value ? await savePrices() : await netPricesDelete(queryParams);
    toasts.add({
        type: 'success',
        text: !matrixHasNetPrice.value ? $t('Matrix.net_price_model_deleted_text') : $t('Matrix.net_price_model_saved_text'),
    });
    emit('update:netPricesChanged', true);
    showFromText.value = hasFromText(variantPrices.value, articlePrices.value, matrixHasNetPrice.value, prices);
    !editSuccessful.value ? await getNetPrices() : updateNetPrices();
};

onMounted(() => {
    emit('update:netPricesChanged', false);
});

onBeforeMount(async() => {
    if (!$hasRole(RoleTypes.SUPPLIER)) {
        await getNetPrices();
    }
});
</script>

<style lang="scss" scoped>
.net-prices {
    flex-grow: 1;

    &__matrix-head {
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: sp(xs);
    }

    &__button {
        position: relative;
        display: inline-block;
    }

    &__number-input {
        margin-left: auto;

        :deep(.number-input__arrows) {
            display: none;
        }
    }

    &__edit {
        :deep(.m) {
            width: rem(40);
        }
    }

    &__button-check-icon {
        position: absolute;
        top: rem(-7);
        right: rem(-7);
        width: rem(18);
        height: rem(18);
        pointer-events: none;

        @include helper-color-bg(bright-blue);
        @include helper-border-radius(50%);
        @include helper-flex-centered();
    }

    &__fa-check {
        stroke-width: rem(2);
        position: absolute;

        :deep(.svg-icon) {
            width: rem(12);

            @include helper-color(white);
        }
    }

    &__spinner {
        width: rem(60);
        height: rem(102);

        :deep(.svg-icon) {
            width: rem(102);
            height: rem(102);
        }
    }

    &__tabs {
        width: 100%;
        height: 100%;
        margin: sp(s) 0;

        &-variant-disabled {
            :deep(.tabs__content :nth-child(2)) {
                pointer-events: none;
                opacity: 0.5;
            }
        }

        :deep(.text) {
            @include helper-font-size(6);
            @include helper-font-line-height(4);
            @include helper-font-weight(medium);
        }

        &-info-wrapper {
            width: 100%;

            @include helper-flex-centered();

            flex-direction: column;
            align-items: flex-start;
        }

        &-info {
            margin-bottom: sp(s);

            @include helper-flex-centered();
            @include helper-font-size(small);
            @include helper-font-line-height(regular);
            @include helper-font-weight(regular);
        }

        &-warning {
            width: 100%;
        }

        &-icon {
            margin-right: sp(xxs);

            @include helper-color(corporate-blue);
        }

        &-model {
            width: 100%;
            padding: sp(s) sp(s) 0;

            @include helper-color-bg(light-gray);
            @include helper-border-radius(rem(5));
            @include helper-font-size(6);
            @include helper-font-line-height(4);
            @include helper-font-weight(medium);

            &-input-field {
                &-elem {
                    justify-content: flex-start;
                    width: 100%;
                    height: rem(39);

                    @include helper-flex-centered();
                }
            }
        }

        &-variant {
            width: 100%;
            max-height: rem(500);
            display: flex;
            flex-direction: column;
            overflow: scroll;
            padding: sp(s) sp(s) 0;

            @include helper-color-bg(light-gray);
            @include helper-border-radius(rem(5));
            @include helper-font-size(6);
            @include helper-font-line-height(4);
            @include helper-font-weight(medium);

            &-input-field {
                &-elem {
                    justify-content: flex-start;
                    width: 100%;
                    height: rem(39);

                    @include helper-flex-centered();
                }
            }
        }

        &-article-wrapper {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: scroll;
        }

        &-article {
            width: max-content;

            @include helper-font-size(6);
            @include helper-font-line-height(4);
            @include helper-font-weight(medium);

            &-input-field {
                &-elem {
                    justify-content: flex-start;
                    width: 100%;
                    height: rem(39);

                    @include helper-flex-centered();
                }
            }
        }

        &-delete-prices {
            position: sticky;
            bottom: 0;
            width: 100%;
            height: rem(40);
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: auto;
            margin-bottom: auto;

            @include helper-color-bg(light-gray);
            @include helper-flex-centered();
        }

        &-line {
            @include helper-border-t($setting-color-default-line, rem(1), solid);

            margin: rem(12) 0;
        }
    }

    &__modal {
        display: flex;
        flex-direction: column;

        :deep(.modal-box) {
            max-width: 90%;
            max-height: 90%;
            overflow: scroll;
        }

        :deep(.modal-box__custom-content) {
            display: flex;
            flex-direction: column;
            max-height: rem(600);
            overflow: scroll;
        }

        &-header {
            width: 100%;
        }

        &-title {
            @include helper-font-size(2);
            @include helper-font-line-height(2);
            @include helper-font-weight(medium);
        }
    }

    &__table {
        &-container {
            display: flex;
            flex-direction: column;
            overflow: scroll;
        }

        &-header {
            display: flex;
            flex-direction: row;

            @include helper-border($setting-color-gray-2, rem(1), solid);
            @include helper-border-r($setting-color-gray-2, 0, solid);
            @include helper-color-bg(strong-blue);
            @include helper-color(white);
            @include helper-font-weight(bold);
        }

        &-color-header,
        &-size-headers {
            display: flex;
            align-items: center;
        }

        &-color-header,
        &-size-header {
            @include helper-border-r($setting-color-gray-2, rem(1), solid);

            width: rem(140);
            max-width: rem(140);
            height: rem(40);
            padding: rem(8) sp(s);
        }

        &-body {
            display: flex;
            flex-direction: column;

            @include helper-border-l($setting-color-gray-2, rem(1), solid);
        }

        &-color {
            @include helper-flex-centered();
            @include helper-border-b($setting-color-gray-2, rem(1), solid);
        }

        &-color-label {
            @include helper-color-bg(gray-1);
            @include helper-border-r($setting-color-gray-2, rem(1), solid);

            width: rem(140);
            max-width: rem(140);
            height: rem(40);
            padding: rem(8);
            line-height: rem(24);

            @include helper-ellipse-dots();
        }

        &-values {
            @include helper-flex-centered();

            padding: rem(5) 0 rem(5) sp(s);

            @include helper-border-r($setting-color-gray-2, rem(1), solid);

            width: rem(140);
            max-width: rem(140);
            height: rem(40);
        }

        &-number-input {
            :deep(.number-input) {
                input {
                    border: none;
                    text-align: left;
                }
            }

            :deep(.number-input__arrows) {
                display: none;
            }
        }
    }

    &__lock-icon {
        margin-right: sp(s);

        :deep(svg) {
            @include helper-color(corporate-blue);
        }
    }

    &__lock-bg {
        @include helper-color-bg(light-gray);
    }
}
</style>
