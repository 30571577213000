import type { ApiResponse } from '~/composables/types/api/apiResponse';
import handleError from '~/composables/api/handleResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';
import type { AdditionalOrderFieldRequestParams } from '~/composables/types/api/searchDiscover';

export default async function updateAdditionalOrderFields(params: Omit<AdditionalOrderFieldRequestParams, 'cartId'>): Promise<ApiResponse> {
    const url = '/additional-order-fields';
    const requestParams: AdditionalOrderFieldRequestParams = {
        ...params,
        cartId: useCartsStore().activeCart?.id || ''
    };
    const { fetchCcCartApi } = fetchOrderDetermination();

    if (requestParams.brandCode === '') {
        requestParams.brandCode = '-';
    }

    try {
        const response = await fetchCcCartApi(url, {
            method: 'PUT',
            body: requestParams,
        }) as any;

        return response.status === ResponseStatusTypes.SUCCESS ? {} : { error: response.status };
    } catch (error) {
        return handleError(error);
    }
}
