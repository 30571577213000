import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type {
    Branches,
    ConcreteProductByGtin,
    ConcreteProductBySizeAndColor,
    FetchDataColor,
    FetchDataDeliveryDates,
    MatrixAvailableDeliveryDate,
    MatrixColorAvailability,
    MatrixColorItem,
    MatrixImageByColor,
    MatrixResponse,
    MatrixResponseOptions,
    MatrixSupplier,
    ResponseBranch,
} from '~/composables/types/api/searchDiscover/getMatrix';
import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore';
import useLogger from '~/composables/service/useLogger';

export type PriceEntry = {
    qty: number;
    price: number;
}

const getIntValueOrDefault = (value: string, defaultValue: number) => parseInt(value ?? defaultValue, 10);
const intersportGln = '4025927000007';

const createConcreteProduct = (product: any) => {
    const getPriceType = (priceType: string) => product?.priceDataByPriceType?.[priceType]?.intersportPriceType ?? null;
    const getPrice = (priceType: string) => product.prices[priceType] ?? null;
    const getScalePriceList = () => {
        if (product.labels.includes('MS')) {
            const priceList = product.priceDataByPriceType.DEFAULT.intersportPriceList;

            return Object.fromEntries(
                priceList.map(({ qty, price }: PriceEntry) => [qty, price.toString()])
            );
        }

        return null;
    };

    return {
        name: product.name,
        sku: product.sku,
        catalogId: product.concrete_attributes.attr_catalogID,
        colorKey: product.colorKey ?? '-',
        sizeKey: product.sizeKey ?? '-',
        gtin: product.gtin,
        labels: product.labels,
        minQtyColor: getIntValueOrDefault(product.concrete_attributes.attr_minQtyColor, 1),
        packagingUnit: getIntValueOrDefault(product.pkgUnitKey, 10),
        purchasePrice: getPrice('DEFAULT'),
        purchasePriceIntersportType: getPriceType('DEFAULT'),
        sellPrice: getPrice('PURCHASE'),
        sellPriceIntersportType: getPriceType('PURCHASE'),
        purchasePriceOriginal: getPrice('ORIGINAL'),
        purchasePriceOriginalIntersportType: getPriceType('ORIGINAL'),
        sellPriceOriginal: getPrice('PURCHASE_ORIGINAL'),
        sellPriceOriginalIntersportType: getPriceType('PURCHASE_ORIGINAL'),
        customerGroupAccess: product.customerGroupAccess,
        customerGroupBranchAccess: Object.assign({}, ...(product.customerGroupBranchAccess || [])),
        orderFrom: product.concrete_attributes.attr_orderFrom,
        scalePriceList: getScalePriceList(),
        productAbstractRelations: product.productAbstractRelations,
        purchasePriceFromLabel: undefined,
        sellPriceFromLabel: undefined,
        purchasePriceOriginalFromLabel: undefined,
        sellPriceOriginalFromLabel: undefined,
        stockStatus: product.stockStatus ?? null,
        reAvailabilityDate: product.reAvailabilityDate ?? null,
        availableQuantity: product.availableQuantity ?? null,
    };
};

const createDeliveryDate = (date: any) => ({
    gtin: date.gtin,
    deadlineDate: date.orderdeadlineDate,
    fromDate: date.fromDate,
    toDate: date.toDate,
});

const createMatrixColorItem = (value: FetchDataColor) => ({
    key: value.key ?? '-',
    label: value.label ?? '-',
    imageUrl: value.imageUrl ?? '',
});

const createMatrixSupplier = (supplier: any): MatrixSupplier => ({
    abstractProductId: supplier.abstractId,
    abstractSku: supplier.abstractSku,
    text: supplier.supplierName ?? supplier.supplierGln,
    gln: supplier.supplierGln,
});

const sortSuppliers = (supplierA: MatrixSupplier, supplierB: MatrixSupplier) => {
    if (supplierA.gln === intersportGln && supplierB.gln !== intersportGln) {
        return -1;
    } else if (supplierA.gln !== intersportGln && supplierB.gln === intersportGln) {
        return 1;
    }

    return 0;
};

const buildMatrixResponse = (options: MatrixResponseOptions): MatrixResponse => {
    const {
        data,
        colors,
        allDeliveryDates,
        deliveryDatesByGtin,
        productsByGtin,
        productsBySizeAndColor,
        colorAvailability,
        imagesByColor,
        suppliers,
        minPackagingUnit,
        maxPackagingUnit,
        nosTabVisible
    } = options;

    const allBranches: Branches[] = data.branches.map(
        (branch: ResponseBranch): Branches => {
            return {
                ...branch,
                partnerId: branch.externalId,
            };
        }
    );

    return {
        name: data.abstractAttributes.attr_name,
        colors: colors,
        sizes: data.sizes.map((size: any) => ({ key: size.key, label: size.label })),
        defaultBranches: allBranches.map(branch => (branch.label)),
        branches: allBranches,
        originalBranches: allBranches.map(branch => ({ ...branch })),
        branchNames: data.branches.map((branch: any) => (branch.name)),
        deliveryDateType: data.deliveryDates.length > 0 ? parseInt(data.deliveryDates[0].typeDelivery, 10) : 1,
        allAvailableDeliveryDates: allDeliveryDates,
        availableDeliveryDatesByGtin: deliveryDatesByGtin,
        concreteProductsByGtin: productsByGtin,
        concreteProductsBySizeAndColor: productsBySizeAndColor,
        colorAvailabilities: colorAvailability,
        imagesByColor: imagesByColor,
        suppliers: suppliers,
        minPackagingUnit: minPackagingUnit,
        maxPackagingUnit: maxPackagingUnit,
        minQuantityConcrete: parseInt(data.abstractAttributes.attr_minQty ?? 1, 10),
        minQuantityColor: parseInt(data.abstractAttributes.attr_minQtyColor ?? 1, 10),
        minQuantityAbstract: parseInt(data.abstractAttributes.attr_minQtyModel ?? 1, 10),
        supplierGln: data.abstractAttributes.attr_supplierGLN,
        supplierName: data.abstractAttributes.attr_supplierName,
        supplierNumber: data.abstractAttributes.attr_supplierNo,
        catalogId: data.abstractAttributes.attr_catalogID,
        brandCode: data.abstractAttributes.attr_brandCode,
        brandName: data.abstractAttributes.attr_brandName,
        modelNumber: data.abstractAttributes.attr_modelNo,
        modelNumberFrontend: data.abstractAttributes.attr_modelNo_frontend,
        nosTabVisible: nosTabVisible,
        items: {
            orderItems: [],
            moduleItems: [],
            nosItems: [],
            userFlowState: '',
            loaded: false,
            nosLoaded: false,
            cartClosed: true,
            branchCluster: null,
            modulName: null,
            allocationId: null,
        },
        productAbstractRelations: data.productAbstractRelations,
        productAbstractDoubles: data.productAbstractDoubles,
        intersportGLN: intersportGln === data.abstractAttributes.attr_supplierGLN,
        labels: data.labels,
        pgFedas4Name: data.abstractAttributes.attr_fedas4_name,
        pgFedas4: data.abstractAttributes.attr_fedas4,
        hgg: data.abstractAttributes.attr_fedas_hgg_import,
        pgFedasName: data.abstractAttributes.attr_fedas_name_import,
        branchClustersAvailable: data.branchClustersAvailable,
        idProductAbstract: data.idProductAbstract,
    };
};

export default async function getMatrix(abstractId: string | number): PromiseResponseData<MatrixResponse> {
    const matrixPricesStore = useMatrixPricesStore();
    const { fetchSdApi } = fetchOrderDetermination();
    const url = `/order-matrix/${abstractId}`;
    try {
        const response = await fetchSdApi(url) as any;
        if (response.error) {
            return handleError(response);
        }

        const data = response.data.attributes;
        const deliveryDatesByGtin: {[key: string]: MatrixAvailableDeliveryDate[]} = {};
        const productsByGtin: ConcreteProductByGtin = {};
        const productsBySizeAndColor: ConcreteProductBySizeAndColor = {};
        const colorAvailability: MatrixColorAvailability = {};
        let nosTabVisible = false;

        data.deliveryDates.forEach((date: any) => {
            if (data.concreteProducts.find((product: any) => product.sku === date.sku)) {
                date.gtin = data.concreteProducts.find((product: any) => product.sku === date.sku).gtin;
            }
        });

        const colors: MatrixColorItem[] = Object.values(data.colors as FetchDataColor[]).map(createMatrixColorItem);

        const allDeliveryDates = (data.deliveryDates as FetchDataDeliveryDates[]).map(createDeliveryDate);

        for (const date of allDeliveryDates) {
            deliveryDatesByGtin[date.gtin] ??= [];
            deliveryDatesByGtin[date.gtin].push(date);
        }

        for (const product of data.concreteProducts) {
            const sizeIndex = product?.sizeKey ?? '-';
            productsBySizeAndColor[sizeIndex] ??= {};
            const size = productsBySizeAndColor[sizeIndex];
            const colorIndex = product?.colorKey ?? '-';

            if (product.labels.includes('NOS') || product.labels.includes('SAISON')) {
                nosTabVisible = true;
            }

            productsByGtin[product.gtin] = createConcreteProduct(product);

            if (!size?.[colorIndex]) {
                size[colorIndex] = productsByGtin[product?.gtin] ?? null;
            } else {
                console.error('Conflicting product found', size[colorIndex], product);
            }
        }

        allDeliveryDates.forEach((date: MatrixAvailableDeliveryDate) => {
            const product = productsByGtin[date.gtin];
            if (product) {
                const color = product.colorKey ?? '-';
                colorAvailability[color] ??= [];
                colorAvailability[color].push(date);
            }
        });

        const imagesByColor: MatrixImageByColor = Object.fromEntries(
            Object.values(data.colors ?? '-').map((image: any) => [image.key, image.imageUrl])
        ) || {};

        const minPackagingUnit = Math.min(...data.concreteProducts.map(
            (product: any) => getIntValueOrDefault(product.pkgUnitKey, 10)
        ));
        const maxPackagingUnit = Math.max(...data.concreteProducts.map(
            (product: any) => getIntValueOrDefault(product.pkgUnitKey, 10)
        )) || Number.MIN_SAFE_INTEGER;

        const suppliers = data.productAbstractRelations.suppliers.map(createMatrixSupplier);
        suppliers.sort(sortSuppliers);

        matrixPricesStore.mapPrices(response.data.attributes.concreteProducts);
        matrixPricesStore.initializeStore();

        return handleSuccess<MatrixResponse>(buildMatrixResponse({
            data,
            colors,
            allDeliveryDates,
            deliveryDatesByGtin,
            productsByGtin,
            productsBySizeAndColor,
            colorAvailability,
            imagesByColor,
            suppliers,
            minPackagingUnit,
            maxPackagingUnit,
            nosTabVisible
        }));
    } catch (error: any) {
        useLogger().logError(error);
        return handleError(error);
    }
}
