import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import type {
    BranchClusters,
    BranchClustersResponse
} from '~/composables/types/api/searchDiscover/getBranchClusters';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getBranchClusters(
    abstractIdOrSku: string,
    moduleName: string | null,
    allocationId: string | null,
): PromiseResponseData<BranchClusters> {
    const url = !moduleName ? `/branch-clusters/${abstractIdOrSku}` : `/branch-clusters/${abstractIdOrSku}?moduleName=${moduleName}&moduleId=${allocationId}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const branchClusterResponse = await fetchSdApi(url) as BranchClustersResponse;

        return handleSuccess<BranchClusters>(branchClusterResponse.data?.attributes);
    } catch (error) {
        return handleError(error);
    }
}
