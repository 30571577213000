export default function() {
    const logError = (error: any) => {
        const config = useRuntimeConfig();

        if (!(error instanceof Error)) {
            error = typeof error === 'string' ? new Error(error) : new Error(JSON.stringify(error));
        }

        if (config.bugsnagIsActive) {
            // @ts-ignore
            useBugsnag().notify(error);
        } else {
            console.error(error);
        }
    };

    return {
        logError,
    };
}
