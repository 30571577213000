import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import useParamsToQuery from '~/composables/api/useParamsToQuery';
import type {
    FedasListQueryParams,
    FedasListResponse,
} from '~/composables/types/api/searchDiscover/fedas';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getFedasList(params: FedasListQueryParams) : PromiseResponseData<FedasListResponse> {
    const url: string = `/fedas-by-hgg?${useParamsToQuery(params)}`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi(url) as any;

        if (response.error) {
            return handleError(response);
        }

        return handleSuccess<FedasListResponse>({ fedasList: response.data[0].attributes.fedasList });
    } catch (error: any) {
        return handleError(error);
    }
}
