<template>
    <div v-if="sortedBranches && sortedBranches.length > 0" class="clusters-info" title="">
        <div v-if="!icon" class="clusters-info-branch">
            <span>
                {{ missingModules.branches && missingModules.branches.length > 0 ? `${sortedBranches?.length} / ` : '' }}
                {{ missingModules.branches && missingModules.branches.length > 0 ? missingModules.branches.length + sortedBranches?.length : sortedBranches?.length }}
            </span>
            <span v-if="sortedBranches?.length > 1">{{ $t('General.branches') }}</span>
            <span v-else>{{ $t('General.branch') }}</span>
            <AtomIcon
                name="info"
                bolding="2"
                @mouseenter="mouseEnterInfo($event)"
                @mouseleave="mouseLeaveInfo"
            />
        </div>
        <div v-if="icon" class="clusters-info-color">
            <AtomIcon
                name="info"
                bolding="2"
                @mouseenter="mouseEnterInfo($event)"
                @mouseleave="mouseLeaveInfo"
            />
        </div>
    </div>
    <AtomTooltip
        class="clusters-info__tooltip"
        :element="tooltipElement"
        :offset-x="!icon ? 35 : 25"
        :offset-y="-10"
    >
        <div class="clusters-info__cluster-container">
            <div v-for="(col, colIndex) in dividedColumns" :key="colIndex">
                <span class="clusters-info__first-row">{{ colIndex === 0 ? $t('Matrix.contain') : '' }}</span>
                <div v-for="(row, rowIndex) in col" :key="rowIndex">
                    <span class="cluster-row">{{ row }}</span>
                </div>
            </div>
            <div v-if="missingModules.branches && missingModules.branches.length > 0" class="missing-branches">
                <span class="clusters-info__first-row">{{ $t('Matrix.not_contain') }}</span>
                <div v-for="(branch, index) in missingModules.branches" :key="index">
                    <span class="clusters-info__missing-branch-row">
                        {{ branch }} {{ missingModules.branchNames?.[index] ?? '' }}
                    </span>
                </div>
            </div>
        </div>
    </AtomTooltip>
</template>

<script lang="ts" setup>
import type { MissingMatrixModules } from '~/composables/types/api/cartConditions/matrix';

const props = withDefaults(defineProps<{
    branches: string[] | null,
    branchNames: string[] | null,
    missingModules: MissingMatrixModules,
    icon?: boolean,
}>(), {
    icon: false,
});

const tooltipInfo = ref('');
const tooltipElement = ref<HTMLElement | null>(null);
const dividedColumns = ref<string[][]>([]);

const sortedBranches = computed(() => props.branches ? [...props.branches].sort() : null);
const sortedBranchNames = computed(() => props.branchNames ? [...props.branchNames].sort() : null);

const setDividedColumns = () => {
    dividedColumns.value = [];
    let branchesArr = sortedBranches.value ?? [];
    const branchNamesArr = sortedBranchNames.value ?? [];

    branchesArr = branchesArr.map((branch, index) => `${branch} ${branchNamesArr[index] ?? ''}`);

    for (let i = 0; i < branchesArr.length; i += 10) {
        dividedColumns.value.push(branchesArr.slice(i, i + 10));
    }
};

const mouseEnterInfo = (event: MouseEvent) => {
    setDividedColumns();
    tooltipInfo.value = sortedBranches.value?.toString().replace(',', ' ') ?? '';
    tooltipElement.value = event.target as HTMLElement;
};

const mouseLeaveInfo = () => {
    tooltipElement.value = null;
};
</script>

<style lang="scss" scoped>
.clusters-info {
    z-index: $setting-zi-icon;

    &-branch {
        display: flex;
        align-items: center;
        gap: sp(xxs);
        padding: rem(2) rem(12);

        @include helper-color(corporate-blue);
        @include helper-border($setting-color-corporate-blue);
        @include helper-border-radius(rem(18));
    }

    &-color {
        margin-left: auto;

        @include helper-color(corporate-blue);
    }

    &__cluster-container {
        display: flex;
        flex-direction: row;
        gap: sp(s);
    }

    &__missing-branch-row {
        @include helper-color(fast-red);
    }

    &__first-row {
        display: inline-block;
        min-width: 1rem;
        height: 1rem;
        margin-bottom: sp(s);
    }
}
</style>
