import type { PromiseResponseData } from '~/composables/types/api/apiResponse';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { FedasPost } from '~/composables/types/api/searchDiscover/fedas';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function updateFedas(params: FedasPost): PromiseResponseData<object> {
    const url = `/abstract-products-memberfedas`;
    const { fetchSdApi } = fetchOrderDetermination();
    const fedasData = {
        data: {
            type: `abstract-products-memberfedas`,
            attributes: params,
        },
    };

    try {
        const response = <any> await fetchSdApi(url, {
            method: 'POST',
            body: fedasData
        });

        if (response?.error) {
            return handleError(response);
        }

        return handleSuccess<object>({});
    } catch (error: any) {
        return handleError(error);
    }
}
