import type {
    PromiseResponseData
} from '~/composables/types/api/apiResponse';
import type { MatrixNosItem, MatrixStoredOrderItem } from '~/composables/service/matrix/useMatrixOrderItems';
import handleError, { handleSuccess } from '~/composables/api/handleResponse';
import type { MatrixData } from '~/composables/types/api/searchDiscover/getMatrix';
import { fetchOrderDetermination } from '../apiFactories/fetchOrderDetermination';
import { useMatrixPricesStore } from '~/composables/stores/useMatrixPricesStore';
import type { MatrixOrderItem } from '~/composables/types/api/cartConditions/matrix';

export type MatrixUpdateCartItem = MatrixStoredOrderItem & {
    orderDeadline?: string | null,
}

type MatrixUpdateParams = {
    cartId: string,
    catalogId: string,
    supplierId: string,
    modelCode: string,
    sapModelCode: string,
    brandCode: string,
    typeDelivery: string,
    branchCluster?: string | null,
    items: (MatrixUpdateCartItem[] | MatrixNosItem[]),
}

export default async function updateMatrix(
    matrixData: MatrixData,
    items: MatrixUpdateCartItem[] | MatrixNosItem[],
    cartUuid?: string,
    nosUpdate: boolean = false
): PromiseResponseData {
    const cartsStore = useCartsStore();
    const url = '/models/items';
    const nosUrl = '/nos';
    const { fetchCcCartApi, fetchCcNosApi } = fetchOrderDetermination();

    const params: MatrixUpdateParams = {
        cartId: cartUuid ?? cartsStore.activeCart!.id,
        catalogId: matrixData.catalogId,
        supplierId: matrixData.supplierNumber,
        modelCode: matrixData.modelNumberFrontend,
        sapModelCode: matrixData.modelNumber,
        brandCode: matrixData.brandCode,
        typeDelivery: `0${matrixData.deliveryDateType}`,
        branchCluster: matrixData.items.branchCluster ? matrixData.items.branchCluster.toString() : null,
        items: items,
    };

    try {
        if (nosUpdate) {
            params.items.forEach(obj => {
                if (obj.validTo === '') {
                    delete obj.validTo;
                }
            });

            delete params.branchCluster;

            await fetchCcNosApi(nosUrl, {
                method: 'PUT',
                body: params,
            });
        } else {
            params.items.forEach(obj => obj.colorKey && delete obj.colorKey);

            const response: MatrixOrderItem[] = await fetchCcCartApi(url, {
                method: 'PUT',
                body: params,
            });

            useMatrixPricesStore().setCartOrderItems(response);
        }

        return handleSuccess({});
    } catch (error) {
        return handleError(error);
    }
}
